import { gql } from 'graphql-tag';

export const importContactListSchedule = gql`
mutation importContactListSchedule($contactListId: String, $schedules: [ImportScheduleInput]){
    importContactListSchedule(contactListId: $contactListId, schedules: $schedules){
        id
        isPrimary
        startTime
        endTime
        timezone
    }
}
`; 