import gql from 'graphql-tag';

export const getExternalContactList = gql`
  query getExternalContactList($id: String) {
    getExternalContactList(id: $id) {
      id
      name
      notes
      createdAt
      updatedAt
      deletedAt
      updater
      ExternalContactListMembers {
        id
        name
        description
        phone
        order
      }
      BusinessUnits {
        id
        name
      }
    }
  }
`;
