import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-contact-list-mobile-filter',
  templateUrl: './contact-list-mobile-filter.component.html',
  styleUrls: ['./contact-list-mobile-filter.component.scss']
})
export class ContactListMobileFilterComponent implements OnInit {
  @Input() showMobileFilter: boolean;
  @Output() mobileFilterEvent = new EventEmitter<any>();

  state: string;
  county: string;
  township: string;

  constructor() { }

  ngOnInit() {
  }

  mobileFilter() {
    this.mobileFilterEvent.emit({state: this.state, county: this.county, township: this.township});
  }

  clear() {
    this.state = null;
    this.county = null;
    this.township = null;
    this.mobileFilter();
  }
}
