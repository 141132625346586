import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CONTACT_DETAIL_CONTAINER } from 'src/app/constants/common.constants';
import { BaseContainer } from 'src/app/core/containers/base-container';
import { dynamicSort } from 'src/app/core/functions/common-functions';
import { TabService } from 'src/app/core/services/tab.service';
import { DirtyStatus } from 'src/app/model/common/dirty-status';
import { Contact } from 'src/app/model/contacts/contact';
import { ContactList } from 'src/app/model/contacts/contact-list';
import { ContactListMember } from 'src/app/model/contacts/contact-list-member';
import { AuthService } from 'src/app/services/auth.service';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { ContactBuilderService } from 'src/app/services/contact-builder.service';
import { ContactListApiService } from 'src/app/services/contact-list-api.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { ContactListBuilderService } from 'src/app/services/contact-list-builder.service';
import { ContactListTableService } from 'src/app/services/contact-list-table.service';
import { ContactTableService } from 'src/app/services/contact-table.service';
import { DeviceService } from 'src/app/services/device.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { isNullOrUndefined } from 'util';
import { Schedule } from 'src/app/model/contacts/contact-list-scheduler';
import { MemberCode } from 'src/app/model/contacts/member-code';
import { MultiSelectChangeEvent } from 'primeng/multiselect';

@Component({
  selector: 'app-contact-list-simple-view-container',
  templateUrl: './contact-list-simple-view-container.component.html',
  styleUrls: ['./contact-list-simple-view-container.component.scss'],
})
export class ContactListSimpleViewContainerComponent
  extends BaseContainer
  implements OnInit, OnDestroy
{
  private lContactList: ContactList;
  @Input() loading;
  @Input() set contactList(value: ContactList) {
    this.lContactList = value;
    if (this.lContactList) {
      if (
        !isNullOrUndefined(this.lContactList) &&
        !isNullOrUndefined(this.lContactList.members)
      ) {
        this.orderableMembers = this.lContactList.members.sort(
          dynamicSort('order', 1)
        );
      } else {
        this.orderableMembers = [];
      }
      this.nonOrderableMembers = [];

      this._cdRef.markForCheck();
      this._cdRef.detectChanges();

      // recheck auth for write access due to being embedded or standalone
      if (!this.canEdit) {
        this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([
          'EditContactLists',
        ]);
      }

      this.updateContactList();

      if (
        this._authApi.doesUserHaveAllClaimsFromList(['RotateAllContactLists'])
      ) {
        this.rotateAccess = true;
      } else {
        this._contactApi
          .getContactByEmail(this._auth.getEmail())
          .pipe(take(1))
          .subscribe((data: Contact) => {
            const id = data.id;
            const onList = this.lContactList.members.some(
              (m) => m.contactId === id
            );
            const anUpdater = this.lContactList.updaters.some(
              (u) => u.contactId === id
            );
            // Only want to update this if the user doesn't already have rotate access for some other reason
            // so this doesn't overwrite the user having directReportContactLists claim
            if (!this.rotateAccess) {
              this.rotateAccess =
                (onList &&
                  this._authApi.doesUserHaveAllClaimsFromList([
                    'RotateOwnContactLists',
                  ])) ||
                anUpdater;
            }
            if (!this.rotateAccess) {
              this.orderListStyle = 'contact-list-disable';
              this.dragDropEnabled = false;
            } else {
              this.orderListStyle = 'contact-list';
              this.dragDropEnabled = true;
            }
            this._cdRef.markForCheck();
          });

        if (
          this._authApi.doesUserHaveAllClaimsFromList([
            'RotateDirectReportContactLists',
          ])
        ) {
          this._contactApi
            .queryForWhoReportsToMe(this._auth.getEmail())
            .pipe(take(1))
            .subscribe(({ data }) => {
              const clone = { ...data };
              const reportingIds = clone.queryWhoReportsToMe.map(
                (report) => report.id
              );
              const reportOnList = this.lContactList.members.some((m) =>
                reportingIds.includes(m.contactId)
              );
              if (reportOnList) {
                this.rotateAccess = true;
              }
              if (!this.rotateAccess) {
                this.orderListStyle = 'contact-list-disable';
                this.dragDropEnabled = false;
              } else {
                this.orderListStyle = 'contact-list';
                this.dragDropEnabled = true;
              }
              this._cdRef.markForCheck();
            });
        }
      }

      if (!this.rotateAccess) {
        this.orderListStyle = 'contact-list-disable';
        this.dragDropEnabled = false;
      } else {
        this.orderListStyle = 'contact-list';
        this.dragDropEnabled = true;
      }
    }

    this.selectedBusinessUnits = this.lContactList?.businessUnits?.map(
      (bu) => bu.id
    );
  }

  @Input() embedded = false;
  @Input() contactSchedules: any[] = [];

  @Output() contactsUpdated: EventEmitter<ContactList> = new EventEmitter();

  get contactList() {
    return this.lContactList;
  }

  selectedEmployees: any[] = [];
  selectedManager: any[];
  addContactToListForm: UntypedFormGroup;
  orderableMembers: ContactListMember[] = [];
  nonOrderableMembers: ContactListMember[];
  contactListSchedules: Schedule[];
  saving = false;
  allContacts: Contact[];
  filteredContacts: SelectItem[];
  displayDialog = false;
  displayMemberCodesDialog = false;
  memberCodes: MemberCode[];
  name: string;
  sub: Subscription;
  canEdit = false;
  canEditDirectReports = false;
  canEditOwn = false;
  canEditUpdater = false;
  canAddMemberCodes = false;
  canRemoveMemberCodes = false;
  rotateAccess = false;
  orderListStyle = 'contact-list';
  dragDropEnabled = true;
  empCollapsed = false;
  mgtCollapsed = true;
  codesToDisplay: string = '';
  availableBusinessUnits: SelectItem[];
  selectedBusinessUnits: string[];

  constructor(
    private _contactListApi: ContactListApiService,
    private _contactListTable: ContactListTableService,
    private _contactListBuilder: ContactListBuilderService,
    private _fb: UntypedFormBuilder,
    private _logAndMessage: LogAndMessageService,
    private _authApi: AuthApiService,
    protected _translateService: TranslateService,
    protected _deviceService: DeviceService,
    private _contactApi: ContactApiService,
    private _contactBuilder: ContactBuilderService,
    private _contactTable: ContactTableService,
    private _cdRef: ChangeDetectorRef,
    private _confirmationService: ConfirmationService,
    private _auth: AuthService
  ) {
    super(_deviceService);
    this.addContactToListForm = this._fb.group({
      contact: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.availableBusinessUnits = this._authApi
      .getUserBusinessUnits()
      .map((bu) => {
        return { label: bu.name, value: bu.id };
      });

    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([
      'EditContactLists',
    ]);
    this.canAddMemberCodes = this._authApi.doesUserHaveAllClaimsFromList([
      'AddMemberCode',
    ]);
    this.canRemoveMemberCodes = this._authApi.doesUserHaveAllClaimsFromList([
      'RemoveMemberCode',
    ]);

    if (!this.contactList) {
      this.contactList = this._contactListTable.getSelected();
      if (!this.contactList) {
        // navigate back.
      }
    }

    this.determineCanEditDirectReports();
    this.determineCanEditOwn();
    this.determineCanUpdater();

    this.addContactToListForm = this._fb.group({
      contact: [null, Validators.required],
    });

    this.updateContactList();
    this.selectedManager = [];
    this.selectedEmployees = [];
  }

  ngOnDestroy(): void {
    this.allContacts = null;
    this.filteredContacts = null;
    this.orderableMembers = null;
    this.nonOrderableMembers = null;
    this.contactList = null;
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  updateContactList(): void {
    if (!isNullOrUndefined(this.contactList)) {
      this.memberCodes = this.contactList.memberCodes
        ? [...this.contactList.memberCodes]
        : [];
      this.name = this.contactList.name;
      this.displayMemberCodes();
      this._cdRef.markForCheck();
    }
  }

  openEmployeeProfile($event: any) {
    // clear out the selected to make sure a previously viewed contact isn't used.
    this._contactTable.setSelected(undefined);
    this._contactTable.setScreenEmbedded(false);
    const tab = TabService.getInstance().buildNewTab(
      CONTACT_DETAIL_CONTAINER,
      true,
      null,
      $event
    );
    TabService.getInstance().openTab(tab);
  }

  contactListReorder($event) {
    if (!this.rotateAccess) {
      this._logAndMessage.error(
        'No permission',
        "User doesn't have permissions to rotate this contact list, no changes have been saved."
      );
      return;
    }
    this.saving = true;
    let idx = 1;
    this.orderableMembers.forEach((m) => {
      m.order = idx++;
      m.dirtyStatus = DirtyStatus.UPDATED;
    });
    this.contactList.members = [
      ...this.orderableMembers,
      ...this.nonOrderableMembers,
    ];
    this.saveContactList();
  }

  // TODO: See if we can simplify this.
  findContacts() {
    const contactIdArray = [
      ...this.contactList.members.map((m) => m.contactId),
    ];
    let contactArray = [];
    this._contactApi
      .findContacts()
      .pipe(take(1))
      .subscribe(({ data }) => {
        let clone = [...data.getContacts];
        const innerArray = [
          ...clone.filter((c) => contactIdArray.indexOf(c.id) > -1),
        ];
        contactArray = [
          ...innerArray.map((c) => this._contactBuilder.buildContact(c)),
        ];
        let memberUpdates = [];
        contactArray.forEach((c) => {
          const member = this.contactList.members.filter(
            (m) => m.contactId === c.id
          );
          if (member.length > 0) {
            const newMember = Object.assign({}, member[0]);
            newMember.contact = c;
            memberUpdates.push(Object.assign({}, newMember));
          }
        });
        this.contactList.members = [
          ...memberUpdates.sort(dynamicSort('order', 1)),
        ];
        this.contactList = Object.assign({}, this.contactList);
        memberUpdates = null;
        clone = null;
        data = null;
      });
  }

  saveContactList() {
    this.saving = true;
    const update = this._contactListBuilder.buildUpdateStatement(
      Object.assign({}, this.contactList),
      {
        name: this.name,
        memberCodes: this.memberCodes,
        businessUnits: this.selectedBusinessUnits,
      }
    );
    this._contactListApi
      .updateContactList(this.contactList.id, this.contactList, update)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          this._logAndMessage.translateToSuccessMessage({
            bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
            headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
          });
          let clone = Object.assign({}, data);
          this._contactListApi
            .getContactList(this.contactList.id)
            .pipe(take(1))
            .subscribe((list) => {
              clone = Object.assign({}, list);
              this.contactList = Object.assign(
                {},
                this._contactListBuilder.buildContactList(
                  clone.data.getContactList
                )
              );
              this.setupMemberLists(this.contactList);
              this.contactsUpdated.emit(this.contactList);
              this.saving = false;
              this._cdRef.markForCheck();
              this._cdRef.detectChanges();
            });
          clone = null;
        },
        (error) => {
          this.saving = false;
        }
      );
  }

  setupMemberLists(contactList: ContactList) {
    this.orderableMembers = [];
    this.orderableMembers = [...this.contactList.members].sort(
      dynamicSort('order', 1)
    );
    this._cdRef.markForCheck();
    this._cdRef.detectChanges();
  }

  deleteContact() {
    const today = new Date();
    let memberToRemove = null;
    if (this.selectedEmployees.length > 0) {
      memberToRemove = this.selectedEmployees[0];
    } else if (this.selectedManager.length > 0) {
      memberToRemove = this.selectedManager[0];
    }

    this._contactListApi
      .getContactListSchedules(this.contactList.id)
      .pipe()
      .subscribe(
        ({ data }) => {
          this.contactListSchedules = [...data.getContactListSchedules].map(
            (schedule) =>
              this._contactListBuilder.buildContactListSchedule(schedule)
          );
          if (this.selectedEmployees.length > 0) {
            this.selectedEmployees.forEach((s) => {
              const idx = this.contactList.members.findIndex(
                (m) => m.id === s.id
              );
              let contactSchedule = this.contactListSchedules.filter(
                (f) => f.contact.id === s.contactId
              );
              let currentSchedules = contactSchedule;
              currentSchedules = currentSchedules.filter(
                (c) => c.endTime >= today
              );
              if (currentSchedules.length > 0) {
                this._logAndMessage.translateToErrorMessage({
                  headerKey: 'COMMON.MESSAGES.HEADERS.DELETE_ERROR',
                  bodyKey: 'COMMON.MESSAGES.ERROR.DELETE_CONTACT_ERROR_MSG',
                  bodyParams: {
                    contact: s.contact.fullName,
                  },
                });
              } else {
                // TODO: Translate
                this._confirmationService.confirm({
                  header: 'Remove contact from contact list?',
                  icon: 'fa fa-question-circle',
                  acceptVisible: true,
                  rejectVisible: true,
                  acceptLabel: 'Yes',
                  rejectLabel: 'No',
                  message:
                    'Are you sure you want to remove ' +
                    memberToRemove.contact.firstName +
                    ' ' +
                    memberToRemove.contact.lastName +
                    ' from this contact list?',
                  accept: () => {
                    this.contactList.members.splice(idx, 1);
                    this.saveContactList();

                    if (this.selectedManager.length > 0) {
                      this.selectedManager.forEach((s) => {
                        const idx = this.contactList.members.findIndex(
                          (m) => m.id === s.id
                        );
                        this.contactList.members.splice(idx, 1);
                      });
                    }
                  },
                  reject: () => {
                    this.saving = false;
                    this._cdRef.markForCheck();
                  },
                });
              }
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  addContact() {
    if (!this.allContacts) {
      this._contactApi
        .findContacts()
        .pipe(take(1))
        .subscribe(({ data }) => {
          const clone = [...data.getContacts];
          this.allContacts = [
            ...clone
              .map((c) => this._contactBuilder.buildContact(c))
              .sort(dynamicSort('firstName', 1)),
          ];
          this.filteredContacts = [
            ...this.allContacts.map((c) => ({
              label: c.firstName + ' ' + c.lastName,
              value: c.id,
            })),
          ];
          this.displayDialog = true;
        });
    } else {
      this.displayDialog = true;
    }
  }

  contactSearch($event) {
    if ($event.query) {
      this.filteredContacts = this.allContacts
        .filter((c) =>
          (c.firstName + ' ' + c.lastName)
            .toLowerCase()
            .startsWith($event.query.toLowerCase())
        )
        .filter((c: Contact) => c.inactiveAdAccount === false)
        .map((c) => ({ label: c.firstName + ' ' + c.lastName, value: c.id }));
    } else {
      this.filteredContacts = this.allContacts.map((c) => ({
        label: c.firstName + ' ' + c.lastName,
        value: c.id,
      }));
    }
  }

  addContactToList() {
    const raw = this.addContactToListForm.getRawValue();
    const array = this.allContacts.filter((c) => c.id === raw.contact.value);
    if (array.length > 0) {
      this.contactList.members = [
        ...this.contactList.members,
        this._contactListBuilder.buildNewMember(
          this.contactList.id,
          array[0],
          this.orderableMembers.length + 1
        ),
      ];
      this.saveContactList();
    }

    this.addContactToListForm.reset();
    this.displayDialog = false;
  }

  listChange($event) {
    this.saveContactList();
  }

  addMemberCode($event) {
    if (!this.canAddMemberCodes) {
      this._logAndMessage.error(
        'No Permission',
        'User has no permission to add member codes'
      );
      return;
    }

    this.saveContactList();
  }

  removeMemberCode($event) {
    if (!this.canRemoveMemberCodes) {
      this._logAndMessage.error(
        'No Permission',
        'User has no permission to remove member codes'
      );
      return;
    }

    this.saveContactList();
  }

  onSelectionChange($event) {
    this.selectedManager = [];
  }

  manageMemberCodes() {
    this.displayMemberCodesDialog = true;
  }

  isSelected(member) {
    if (this.selectedManager.length > 0) {
      return this.selectedManager.some((m) => m.id === member.id);
    }
    return false;
  }

  onMemberCodesHidden() {
    this.displayMemberCodesDialog = false;
  }

  onMemberCodesUpdated($event: MemberCode[]) {
    this.codesToDisplay = '';
    this.saving = true;
    this.displayMemberCodesDialog = false;
    this.memberCodes = $event;

    this.displayMemberCodes();
    this.saveContactList();
  }

  displayMemberCodes() {
    let assignedMemberCodes = [];
    this.memberCodes.forEach((c) => {
      assignedMemberCodes.push(c.code);
    });

    this.codesToDisplay = assignedMemberCodes.join(', ');
  }

  handleBusinessUnitChange(event: MultiSelectChangeEvent) {
    this.saveContactList();
  }

  determineCanEditDirectReports() {
    if (!this._authApi.doesUserHaveAllClaimsFromList(['AddRemoveDirectReportsContactList'])) {
      return;
    }

    for (let member of this.contactList.members) {
      if (this._auth.getUserName().toLowerCase().includes(member.contact?.supervisor?.toLowerCase())) {
        this.canEditDirectReports = true;
        break;
      }
    }
  }

  determineCanEditOwn() {
    if (!this._authApi.doesUserHaveAllClaimsFromList(['AddRemoveOwnContactList'])) {
      return;
    }

    for (let member of this.contactList.members) {
      if (this._auth.getUserName().toLowerCase().includes(member.contact?.fullName?.toLowerCase())) {
        this.canEditOwn = true;
        break;
      }
    }
  }

  determineCanUpdater() {
    if (!this._authApi.doesUserHaveAllClaimsFromList(['AddRemoveUpdaterContactList'])) {
      return;
    }

    this._contactApi
    .getContactByEmail(this._auth.getEmail())
    .subscribe((data: Contact) => {
      if (data) {
        for (let updater of this.contactList.updaters) {
          if (updater.contactId === data.id) {
            this.canEditUpdater = true;
            break;
          }
        }
      }
    });
  }
}
