<app-progress-spinner [enabled]="loaded === false"></app-progress-spinner>
<p-blockUI [blocked]="saving === true"></p-blockUI>
<div class="dialog-container" *ngIf="loaded === true">
  <div>
    <form [formGroup]="form" id="contact-list-create-form">
      <div class="form-top">
        <div class="button-wrapper row" *ngIf="canCreate === true">
          <div class="member-code-button">
            <button
              pButton
              label="{{ 'CONTACT.LABEL.MANAGE_MEMBER_CODES' | translate }}"
              icon="fa fa-fw fa-tags"
              (click)="manageMemberCodes()"
            ></button>
          </div>
          <div>
            <app-progress-spinner [enabled]="saving"></app-progress-spinner>
          </div>
          <div>
            <button
              pButton
              label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
              icon="fa fa-fw fa-save"
              (click)="saveContactList({})"
              [disabled]="!this.form.valid || saving"
            ></button>
          </div>
        </div>
      </div>

      <div>
        <div class="form-body">
          <div class="contact-list-row">
            <div class="contact-list-label">
              {{ "CONTACT.LABEL.NAME" | translate }}:
            </div>
            <div class="input">
              <input
                pInput
                formControlName="name"
                type="text"
                pInputText
                class="w-100"
              />
            </div>
            <span class="label member-codes-label"
              >{{ "CONTACT.LABEL.MEMBER_CODES" | translate }}:</span
            >
            <div>{{ this.codesToDisplay }}</div>
          </div>
          <div class="contact-list-row">
            <div class="contact-list-label">
              {{ "COMMON.LABEL.BUSINESS_UNITS" | translate }}:<span
                class="required"
                >*</span
              >
            </div>
            <div class="input">
              <p-multiSelect
                [options]="availableBusinessUnits"
                formControlName="businessUnits"
                size="4"
                [style]="{ width: 'inherit' }"
              >
              </p-multiSelect>
            </div>
          </div>
        </div>
      </div>

      <div class="members-group">
        <div>
          <h4>Contact List Members</h4>
        </div>
        <div>
          <form [formGroup]="addContactToListForm" class="member-form">
            <div class="form-input">
              <span class="label"
                >{{ "CONTACT.LABEL.CONTACT" | translate }}:</span
              >
              <span>
                <p-autoComplete
                  [suggestions]="filteredContacts"
                  (completeMethod)="contactSearch($event)"
                  formControlName="contact"
                  [dropdown]="true"
                  field="label"
                  appendTo="body"
                ></p-autoComplete>
              </span>
            </div>
            <div class="add">
              <button
                pButton
                label="{{ 'COMMON.LABEL.BUTTONS.ADD' | translate }}"
                icon="fa fa-fw fa-plus"
                (click)="addContact()"
                [disabled]="!this.addContactsReady || saving"
              ></button>
            </div>
          </form>
        </div>
      </div>

      <div *ngFor="let m of members">
        <div>
          <p-panel [showHeader]="false">
            <div class="member-row">
              <div class="member-column">
                <i
                  class="fa fa-fw fa-minus-circle loc-delete-icon"
                  (click)="deleteMember(m)"
                ></i>
              </div>
              <div class="member-column">
                <span class="fw-700"
                  >{{ "CONTACT.LABEL.NAME" | translate }}:</span
                >
                {{ m.contact?.firstName }}
                {{ m.contact?.lastName }}
              </div>
            </div>
          </p-panel>
        </div>
      </div>
    </form>
  </div>
</div>

<app-member-codes-form
  [isVisible]="displayMemberCodesDialog"
  formTitle="Manage Member Codes"
  (memberCodesHidden)="onMemberCodesHidden()"
  (memberCodesUpdated)="onMemberCodesUpdated($event)"
  #memberCodeForm
></app-member-codes-form>
