import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactsSearchContainerComponent } from './containers/contacts-search/contacts-search-container.component';
import { AuthGuard } from '../guards/auth.guard';
import { ContactDetailsContainerComponent } from './containers/contact-details/contact-details-container.component';
import { CanDeactivateGuard } from '../guards/deactivate.guard';
import { ContactListSearchContainerComponent } from './containers/contact-list-search/contact-list-search-container.component';
import { ContactListDetailViewContainerComponent } from './containers/contact-list-detail-view/contact-list-detail-view-container.component';
import { ContactListCreateContainerComponent } from './containers/contact-list-create/contact-list-create-container.component';
import { ContactProfileContainerComponent } from './containers/contact-profile/contact-profile-container.component';
import { ExternalContactListSearchContainerComponent } from './containers/external-contact-list-search/external-contact-list-search-container.component';
import { ExternalContactListDetailsContainerComponent } from './containers/external-contact-list-details/external-contact-list-details-container.component';

const routes: Routes = [
  {
    path: 'contact',
    component: ContactsSearchContainerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'contact/details/:id',
    component: ContactDetailsContainerComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'contact/details',
    component: ContactDetailsContainerComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'me',
    component: ContactProfileContainerComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'contactList',
    component: ContactListSearchContainerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'contactList/external',
    component: ExternalContactListSearchContainerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'contactList/details',
    component: ContactListDetailViewContainerComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'contactList/details/:id',
    component: ContactListDetailViewContainerComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'contactList/create',
    component: ContactListCreateContainerComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'contactList/external/details',
    component: ExternalContactListDetailsContainerComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'contactList/external/details/:id',
    component: ExternalContactListDetailsContainerComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContactsRoutingModule {}
