import { Column } from 'src/app/model/common/column';

export function getExternalContactListColumns(): Column[] {
  return  [
    {
      field: 'name',
      header: 'CONTACT.LABEL.NAME',
      mobileHeader: 'CONTACT.LABEL.NAME',
      formatPattern: 'hyperlink',
      width: '100%',
      sortable: true,
      filterable: true,
      matchMode: 'contains'
    }
  ];
}
