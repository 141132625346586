import gql from 'graphql-tag';

export const createContactList = gql`
  mutation createContactList($input: CreateContactListInput) {
    createContactList(input: $input) {
      id
      MemberCodes {
        id
        code
      }
      name
      ContactListUpdaters {
        id
        ContactId
      }
      BusinessUnits {
        id
        name
      }
      ContactListMembers {
        id
        ContactId
        order
      }
      Facilities {
        id
        Locations {
          id
          state
          county
          township
        }
      }
    }
  }
`;
