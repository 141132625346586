import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { CONTACT_LIST_DETAIL_CONTAINER } from 'src/app/constants/common.constants';
import { FormComponent } from 'src/app/core/containers/form.component';
import { dynamicSort } from 'src/app/core/functions/common-functions';
import { TabService } from 'src/app/core/services/tab.service';
import { DirtyStatus } from 'src/app/model/common/dirty-status';
import { Contact } from 'src/app/model/contacts/contact';
import { ContactList } from 'src/app/model/contacts/contact-list';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { ContactBuilderService } from 'src/app/services/contact-builder.service';
import { ContactListApiService } from 'src/app/services/contact-list-api.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { ContactListBuilderService } from 'src/app/services/contact-list-builder.service';
import { ContactListTableService } from 'src/app/services/contact-list-table.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { v4 as uuid } from 'uuid';
import { MemberCode } from 'src/app/model/contacts/member-code';

@Component({
  selector: 'app-contact-list-create-container',
  templateUrl: './contact-list-create-container.component.html',
  styleUrls: ['./contact-list-create-container.component.scss'],
})
export class ContactListCreateContainerComponent
  extends FormComponent
  implements OnInit
{
  @Input() embedded = false;
  @Output() newContactCreatedEvent = new EventEmitter<any>();

  saving = false;
  allContacts: Contact[];
  filteredContacts: SelectItem[];
  displayDialog = false;
  addContactToListForm: UntypedFormGroup;
  members: any[];
  addContactsReady = false;
  canCreate = false;
  loaded = false;
  displayMemberCodesDialog: boolean = false;
  memberCodes: MemberCode[];
  codesToDisplay: string = '';
  availableBusinessUnits: SelectItem[];

  constructor(
    private _contactListTable: ContactListTableService,
    private _contactListApi: ContactListApiService,
    private _contactListBuilder: ContactListBuilderService,
    private _fb: UntypedFormBuilder,
    private _logAndMessage: LogAndMessageService,
    private _authApi: AuthApiService,
    protected _translateService: TranslateService,
    protected _deviceService: DeviceService,
    protected _confirmationService: ConfirmationService,
    private _contactApi: ContactApiService,
    private _contactBuilder: ContactBuilderService,
    private _loader: LoadingService
  ) {
    super(_confirmationService, _translateService, _deviceService);
  }

  ngOnInit() {
    this.addContactToListForm = this._fb.group({
      contact: [null, Validators.required],
    });

    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.subscribe((event) => {
        this.initialize();
      });
    }
  }

  initialize() {
    this.loaded = true;
    this.members = [];

    this.availableBusinessUnits = this._authApi
      .getUserBusinessUnits()
      .map((bu) => {
        return { label: bu.name, value: bu.id };
      });

    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      'CreateContactLists',
    ]);

    if (this.embedded === false) {
      // setup the bread crumb.
      const list$ = this._translateService.get('CONTACT.SCREEN.CONTACT_LIST');
      const create$ = this._translateService.get(
        'CONTACT.SCREEN.CONTACT_LIST_CREATE'
      );
      forkJoin([list$, create$])
        .pipe(take(1))
        .subscribe((messages) => {
          this.screenName = messages[1];
          TabService.getInstance().updateActiveTabLabel(this.screenName);
        });
    }

    this.form = this._fb.group({
      name: [null, Validators.required],
      memberCodes: [null],
      businessUnits: [null, Validators.required],
    });

    this._contactApi
      .findContacts()
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = [...data.getContacts];
        this.allContacts = clone
          .map((c) => this._contactBuilder.buildContact(c))
          .sort(dynamicSort('firstName', 1));
        this.addContactsReady = true;
      });
  }

  saveContactList($event) {
    this.saving = true;

    if (this.members) {
      const raw = this.form.getRawValue();
      const contactList: ContactList = {
        id: uuid(),
        members: [],
        updaters: [],
        origMemberCodes: [],
        memberCodes: [],
        businessUnits: [],
        name: raw.name,
      };
      const contactListInput = {
        id: contactList.id,
        name: contactList.name,
        memberCodes: this.memberCodes?.map((c) => ({
          id: c.id,
          code: c.code,
        })),
        members: this.members.map((m) => ({
          id: uuid(),
          ContactListId: contactList.id,
          ContactId: m.contact.id,
          order: m.order,
          dirtyStatus: DirtyStatus.NEW,
        })),
        businessUnits: raw.businessUnits.map((bu) => {
          return {
            id: bu,
          };
        }),
      };

      this._contactListApi
        .createContactList(contactList, contactListInput)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            this._logAndMessage.translateToSuccessMessage({
              bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
              headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
            });
            const newList = this._contactListBuilder.buildContactList(
              data.createContactList
            );
            if (this.embedded === false) {
              this.form.reset();
              this._contactListTable.setSelected(newList);
              const oldTab = TabService.getInstance().getActiveTab();
              const tab = TabService.getInstance().buildNewTab(
                CONTACT_LIST_DETAIL_CONTAINER,
                true,
                null,
                newList.id
              );
              TabService.getInstance().replaceTab(oldTab, tab);
            } else {
              this.newContactCreatedEvent.emit(newList);
            }
            this.saving = false;
          },
          (error) => {
            // TODO: Error message.
            console.log(error);
            this.saving = false;
          }
        );
    } else {
      // TODO: Error message.
      this._logAndMessage.translateToErrorMessage({
        headerKey: 'CONTACT.MESSAGES.HEADERS.CONTACT_LIST_MISSING_LEVEL',
        bodyKey: 'CONTACT.MESSAGES.ERROR.CONTACT_LIST_MISSING_LEVEL_MSG',
      });
      this.saving = false;
    }
  }

  deleteMember(memberToRemove) {
    const idx = this.members.findIndex(
      (m) => m.contact.id === memberToRemove.contact.id
    );
    this.members.splice(idx, 1);
    this.members = [...this.members];
  }

  addContact() {
    const raw = this.addContactToListForm.getRawValue();
    const array = this.allContacts.filter((c) => c.id === raw.contact.value);
    if (array.length > 0) {
      this.members = [
        ...this.members,
        this._contactListBuilder.buildNewMember(
          null,
          array[0],
          this.members.length + 1
        ),
      ];
    }

    this.addContactToListForm.reset();
  }

  contactSearch($event) {
    if ($event.query) {
      this.filteredContacts = this.allContacts
        .filter((c) =>
          (c.firstName + ' ' + c.lastName)
            .toLowerCase()
            .startsWith($event.query.toLowerCase())
        )
        .filter((c: Contact) => c.inactiveAdAccount === false)
        .map((c) => ({ label: c.firstName + ' ' + c.lastName, value: c.id }));
    } else {
      this.filteredContacts = this.allContacts.map((c) => ({
        label: c.firstName + ' ' + c.lastName,
        value: c.id,
      }));
    }
  }

  manageMemberCodes() {
    this.displayMemberCodesDialog = true;
  }

  onMemberCodesHidden() {
    this.displayMemberCodesDialog = false;
  }

  onMemberCodesUpdated($event: MemberCode[]) {
    this.displayMemberCodesDialog = false;
    this.memberCodes = $event;

    this.displayMemberCodes();
  }

  displayMemberCodes() {
    this.codesToDisplay = '';
    let assignedMemberCodes = [];
    this.memberCodes.forEach((c) => {
      assignedMemberCodes.push(c.code);
    });

    this.codesToDisplay = assignedMemberCodes.join(', ');
  }
}
