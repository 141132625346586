import gql from 'graphql-tag';

export const updateContactList = gql`
mutation updateContactList($id: ID!, $input: UpdateContactListInput) {
  updateContactList(id: $id, input: $input) {
    id
    MemberCodes{
      id
      code
    }
    name
    ContactListUpdaters {
      id
      ContactId
    }
    ContactListMembers {
      id
      ContactId
      order
    },
    Facilities {
      id
      Locations {
        id
        state
        county
        township
      }
    }
  }
}
`;
