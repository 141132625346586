import { SelectItem } from 'primeng/api';
import { Column } from 'src/app/model/common/column';

export function getContactListColumns(options: SelectItem[]): Column[] {
  return [
    {
      field: 'state',
      header: 'LOCATION.LABEL.STATE',
      mobileHeader: 'LOCATION.LABEL.STATE',
      formatPattern: 'text',
      width: '15%',
      sortable: true,
      filterable: true,
      matchMode: 'contains'
    },
    {
      field: 'county',
      header: 'LOCATION.LABEL.COUNTY',
      mobileHeader: 'LOCATION.LABEL.COUNTY',
      formatPattern: 'text',
      width: '15%',
      sortable: true,
      filterable: true,
      matchMode: 'contains'
    },
    {
      field: 'township',
      header: 'LOCATION.LABEL.TOWNSHIP',
      mobileHeader: 'LOCATION.LABEL.TOWNSHIP',
      formatPattern: 'text',
      width: '15%',
      sortable: true,
      filterable: true,
      matchMode: 'contains'
    },
    {
      field: 'name',
      header: 'CONTACT.LABEL.NAME',
      mobileHeader: 'CONTACT.LABEL.NAME',
      formatPattern: 'hyperlink',
      width: '20%',
      sortable: true,
      filterable: true,
      matchMode: 'contains',
    },
    {
      field: 'code',
      header: 'CONTACT.LABEL.MEMBER_CODES',
      formatPattern: 'text',
      width: '20%',
      sortable: true,
      filterable: true,
      matchMode: 'contains'
    },
  ];
}
