import gql from 'graphql-tag';

export const createExternalContactList = gql`
  mutation createExternalContactList($input: ExternalContactListInput) {
    createExternalContactList(input: $input) {
      id
      name
      notes
      createdAt
      updatedAt
      deletedAt
      updater
      ExternalContactListMembers {
        id
        name
        description
        phone
        order
      }
      BusinessUnits {
        id
        name
      }
    }
  }
`;
