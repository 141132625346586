import gql from 'graphql-tag';

export const queryForContactLists = gql`
query queryForContactLists($limit: Int, $page: Int, $query: ContactListSortOrQuery, $sort: ContactListSortOrQuery) {
  queryForContactLists(limit: $limit, page: $page, query: $query, sort: $sort) {
    items {
      id
      name
      state
      county
      township
      memberCodes
    }
    totalRecords
  }
}`;
