import gql from 'graphql-tag';

export const getExternalContactLists = gql`
  query getExternalContactLists {
    getExternalContactLists {
      id
      name
      notes
      createdAt
      updatedAt
      deletedAt
      updater
      ExternalContactListMembers {
        id
        name
        description
        phone
        order
      }
      BusinessUnits {
        id
        name
      }
    }
  }
`;
