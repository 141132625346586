<p-blockUI [blocked]="loading === true"></p-blockUI>
<div
  id="contact-action-buttons"
  *ngIf="ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])"
>
  <div class="buttons">
    <app-business-unit-filter
      (selectedBusinessUnitsChanged)="handleBusinessUnitChange($event)"
      class="margin-right"
      #buFilter
    ></app-business-unit-filter>
    <div class="button-wrapper margin-right" *ngIf="canEdit">
      <button
        pButton
        label="{{ 'COMMON.LABEL.BUTTONS.NEW' | translate }}"
        icon="fa fa-fw fa-plus"
        (click)="newContactList()"
      ></button>
    </div>
    <div class="button-wrapper ui-input-group">
      <button
        pButton
        class="margin-right"
        label="{{ 'COMMON.LABEL.BUTTONS.CLEAR' | translate }}"
        icon="fa fa-fw fa-eraser"
        (click)="clearScreen()"
      ></button>
      <button pButton icon="fa fa-fw fa-refresh" (click)="refresh()"></button>
    </div>
  </div>
</div>

<p-panel
  [showHeader]="true"
  header="Filters"
  [toggleable]="true"
  [(collapsed)]="mobileFilterCollapsed"
  *ngIf="ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])"
>
  <div>
    <div>
      <app-external-contact-list-mobile-filter
        (mobileFilterEvent)="mobileFilter($event)"
      >
      </app-external-contact-list-mobile-filter>
    </div>
  </div>
</p-panel>

<app-progress-spinner [enabled]="!elements"></app-progress-spinner>
<div [style.display]="!elements ? 'none' : ''">
  <app-general-grid
    [values]="elements"
    [columns]="columns"
    [loading]="loading"
    [deviceSize]="getDeviceSize()"
    [rows]="rows"
    [totalRecords]="totalRecords"
    [filterable]="true"
    [screenName]="screenName"
    [lazy]="true"
    (lazyLoad)="lazyLoad($event)"
    [includeExportCSV]="false"
    (changeViewEvent)="changeViewEvent($event)"
    [scrollHeight]="scrollHeight"
    [usePaginator]="true"
    [rowsPerPageOptions]="[50, 100, 200]"
    #grid
  >
  </app-general-grid>
</div>
