import gql from 'graphql-tag';

export const getContactListMembers = gql`
  query getContactListMembers($id: String!) {
    getContactListMembers(id: $id) {
      id
      firstName
      lastName
      nickName
      inactiveAdAccount
      afterHoursPhoneNumber
      speedDial
      version
      Company {
        id
        name
        abbreviation
        version
      }
      adAccountName
      JobRole {
        id
        name
        version
      }
      WhenUnavailable {
        id
        firstName
        lastName
      }
      ReportsTo {
        id
        firstName
        lastName
        ReportsTo {
          id
          firstName
          lastName
          ReportsTo {
            id
            firstName
            lastName
          }
        }
        version
      }
      businessMobile
      businessPhone
      home
      other
      note
      personalMobile
      adAccountName
      city
      Availabilities {
        id
        ContactId
        start
        end
        version
      }
    }
  }
`;
