import gql from 'graphql-tag';

export const getContactListForFacility = gql`
  query getContactListForFacility($facilityId: String!) {
    getContactListForFacility(facilityId: $facilityId) {
      id
      createdAt
      updatedAt
      deletedAt
      MemberCodes {
        id
        code
      }
      BusinessUnits {
        id
        name
      }
      name
      ContactListUpdaters {
        id
        ContactId
      }
      ContactListMembers {
        id
        ContactId
        order
        isScheduled
        Contact {
          id
          firstName
          lastName
          nickName
          inactiveAdAccount
          afterHoursPhoneNumber
          speedDial
          version
          Company {
            id
            name
            abbreviation
            version
          }
          adAccountName
          JobRole {
            id
            name
            version
          }
          WhenUnavailable {
            id
            firstName
            lastName
          }
          ReportsTo {
            id
            firstName
            lastName
            businessMobile
            ReportsTo {
              id
              firstName
              lastName
              businessMobile
            }
            version
          }
          businessMobile
          businessPhone
          home
          other
          note
          personalMobile
          adAccountName
          city
          Availabilities {
            id
            ContactId
            start
            end
            version
          }
        }
      }
    }
  }
`;
