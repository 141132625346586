import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ContactListMember } from 'src/app/model/contacts/contact-list-member';
import { BaseComponent } from 'src/app/core/components/base.component';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-contact-list-card',
  templateUrl: './contact-list-card.component.html',
  styleUrls: ['./contact-list-card.component.scss'],
})
export class ContactListCardComponent extends BaseComponent implements OnInit {
  private lIndex: number;
  @Input() set index(i: number) {
    this.lIndex = i + 1;
  }
  get index(): number {
    return this.lIndex;
  }
  @Input() member: ContactListMember;
  @Output() openProfileEvent = new EventEmitter<any>();
  @Output() selectEvent = new EventEmitter<any>();

  @Input() selected = false;

  constructor(private _themeService: ThemeService) {
    super();
  }

  ngOnInit() {}

  openProfile(id = null) {
    if (!id) {
      id = this.member.contact.id;
    }
    this.openProfileEvent.emit(id);
  }

  cardClassName() {
    const selectedTheme = this._themeService.getSelectedTheme();
    const isDark = selectedTheme === 'arya-green';
    const classAdjust = isDark ? '-dark' : '';

    // Check if contact is unavailable
    if (
      this.member.contact.isUnavailable === 'TRUE' ||
      this.member.contact.inactiveAdAccount
    ) {
      return (
        'ui-helper-clearfix contact-list-item contact-list-item-unavail' +
        classAdjust
      );
    }

    // Check if contact is currently scheduled
    else if (this.member.isScheduled) {
      return (
        'ui-helper-clearfix contact-list-item contact-list-item-scheduled' +
        classAdjust
      );
    } else {
      return (
        'ui-helper-clearfix contact-list-item contact-list-item-avail' +
        classAdjust
      );
    }
  }
}
