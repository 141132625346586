import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-external-contact-list-mobile-filter',
  templateUrl: './external-contact-list-mobile-filter.component.html',
  styleUrls: ['./external-contact-list-mobile-filter.component.scss']
})
export class ExternalContactListMobileFilterComponent implements OnInit {

  @Output() mobileFilterEvent = new EventEmitter<any>();

  name: string;

  constructor() { }

  ngOnInit() {  }

  mobileFilter() {
    this.mobileFilterEvent.emit({name: this.name});
  }

  clear() {
    this.name = null;
    this.mobileFilter();
  }
}
