import { Injectable } from '@angular/core';
import { AppsyncService } from './appsync.service';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { WatchQueryOptions } from 'apollo-client';
import { LogAndMessageService } from './log-and-message.service';
import { queryForContactLists } from '../graphql/contact-lists/query-for-contact-lists';
import { getContactLists } from '../graphql/contact-lists/get-contact-lists';
import { getContactList } from '../graphql/contact-lists/get-contact-list';
import { createContactList } from '../graphql/contact-lists/create-contact-list';
import { updateContactList } from '../graphql/contact-lists/update-contact-list';
import { getExternalContactLists } from '../graphql/contact-lists/get-external-contact-lists';
import { getExternalContactList } from '../graphql/contact-lists/get-external-contact-list';
import { updateExternalContactList } from '../graphql/contact-lists/update-external-contact-list';
import { createExternalContactList } from '../graphql/contact-lists/create-external-contact-list';
import { deleteExternalContactList } from '../graphql/contact-lists/delete-external-contact-list';
import { queryForExternalContactLists } from '../graphql/contact-lists/query-for-external-contact-lists';
import { getContactListHistory } from '../graphql/contact-lists/get-contact-list-history';
import { getExternalContactListHistory } from '../graphql/contact-lists/get-external-contact-list-history';
import { getContactListForContact } from '../graphql/contact-lists/get-contact-list-for-contact';
import { deleteContactList } from '../graphql/contact-lists/delete-contact-list';
import { getContactListMembers } from '../graphql/contact-lists/get-contact-list-members';
import { TC_CLAIMS_HEADER } from '../constants/auth-constants';
import { AuthApiService } from './auth-api.service';
import { getContactListForFacility } from '../graphql/contact-lists/get-contact-list-for-facility';
import { getManagementContactListForFacility } from '../graphql/contact-lists/get-management-contact-list-for-facility';
import { addContactListSchedule } from '../graphql/contact-lists/add-contact-list-schedule';
import { deleteContactListSchedule } from '../graphql/contact-lists/delete-contact-list-schedule';
import { editContactListSchedule } from '../graphql/contact-lists/edit-contact-list-schedule';
import { getContactListSchedules } from '../graphql/contact-lists/get-contact-list-schedules';
import { importContactListSchedule } from '../graphql/contact-lists/import-contact-list-schedule';
import { ContactListScheduleImport } from '../model/contacts/contact-list-schedule-import';
import { BaseAppSyncService } from './base-app-sync.service';
import { getContactListWithSortedMembers } from '../graphql/contact-lists/get-contact-list-with-sorted-members';
import { ContactList } from '../model/contacts/contact-list';
import { ContactListBuilderService } from './contact-list-builder.service';
import { ConfigItem } from '../model/common/config-item';
import { getContactListsForDropdown } from '../graphql/contact-lists/get-contact-lists-for-dropdown';
@Injectable({
  providedIn: 'root',
})
export class ContactListApiService extends BaseAppSyncService {
  constructor(
    protected _api: AppsyncService,
    protected _authApi: AuthApiService,
    private _contactListBuilder: ContactListBuilderService
  ) {
    super(_api);
  }

  getContactList(id: string): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getContactList,
          fetchPolicy: 'no-cache',
          variables: {
            id,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  getContactListForContact(id: string): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getContactListForContact,
          fetchPolicy: 'no-cache',
          variables: {
            id,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  findContactLists(): Observable<ContactList[]> {
    return this.query(
      getContactLists,
      {},
      (items: any[]) =>
        items.map((i) => this._contactListBuilder.buildContactList(i)),
      'getContactLists',
      'cache-first'
    );
  }

  findContactListsForDropdown(): Observable<ConfigItem[]> {
    return this.query(
      getContactListsForDropdown,
      {},
      (items: any[]) =>
        items.map((i) =>
          this._contactListBuilder.buildContactListForDropdown(i)
        ),
      'getContactListsForDropdown',
      'cache-first'
    );
  }

  getExternalContactList(id: string): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getExternalContactList,
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
          variables: {
            id,
          },
        };
        return client.query(options);
      })
    );
  }

  findExternalContactLists(): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getExternalContactLists,
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
          fetchPolicy: 'no-cache',
        };
        return client.query(options);
      })
    );
  }

  createContactList(contactList, contactListInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: createContactList,
          variables: { input: contactListInput },
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  createExternalContactList(contactList, contactListInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: createExternalContactList,
          variables: { input: contactListInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
          fetchPolicy: 'no-cache',
        });
      })
    );
  }

  updateContactList(id, contactList, contactListInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: updateContactList,
          variables: { id: id, input: contactListInput },
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  updateExternalContactList(
    id,
    contactList,
    contactListInput
  ): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: updateExternalContactList,
          variables: { id: id, input: contactListInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
          fetchPolicy: 'no-cache',
        });
      })
    );
  }

  deleteExternalContactList(
    id,
    contactList,
    contactListInput
  ): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: deleteExternalContactList,
          variables: { id: id, input: contactListInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
          fetchPolicy: 'no-cache',
        });
      })
    );
  }

  queryForContactLists(
    limit: number,
    page: number,
    query: any,
    sort: any
  ): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: queryForContactLists,
          fetchPolicy: 'no-cache',
          variables: {
            limit,
            page,
            query,
            sort,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  queryForExternalContactLists(
    limit: number,
    page: number,
    query: any,
    sort: any
  ): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: queryForExternalContactLists,
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
          fetchPolicy: 'no-cache',
          variables: {
            limit,
            page,
            query,
            sort,
          },
        };
        return client.query(options);
      })
    );
  }

  findContactListHistory(id: string): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getContactListHistory,
          variables: {
            id: id,
          },
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  findExternalContactListHistory(id: string): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getExternalContactListHistory,
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
          variables: {
            id: id,
          },
          fetchPolicy: 'no-cache',
        };
        return client.query(options);
      })
    );
  }

  getContactListMembers(id: string): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getContactListMembers,
          fetchPolicy: 'no-cache',
          variables: {
            id,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  deleteContactList(id, contactList): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: deleteContactList,
          variables: { id: id },
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  getContactListForFacility(facilityId: String): Observable<ContactList> {
    return this.query(
      getContactListForFacility,
      { facilityId },
      (item) => this._contactListBuilder.buildContactList(item),
      'getContactListForFacility'
    );
  }

  getManagementContactListForFacility(
    facilityId: String
  ): Observable<ContactList> {
    return this.query(
      getManagementContactListForFacility,
      { facilityId },
      (item) => this._contactListBuilder.buildContactList(item),
      'getManagementContactListForFacility'
    );
  }

  addContactListSchedule(contactListScheduleInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: addContactListSchedule,
          variables: { input: contactListScheduleInput },
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  deleteContactListSchedule(id): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: deleteContactListSchedule,
          variables: { id: id },
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  editContactListSchedule(contactListScheduleInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: editContactListSchedule,
          variables: { input: contactListScheduleInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
          fetchPolicy: 'no-cache',
        });
      })
    );
  }

  getContactListSchedules(contactListId: String): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getContactListSchedules,
          fetchPolicy: 'no-cache',
          variables: {
            contact_list_id: contactListId,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  importContactListSchedule(
    contactListId: String,
    schedules: ContactListScheduleImport[]
  ): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: importContactListSchedule,
          variables: { contactListId: contactListId, schedules: schedules },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
          fetchPolicy: 'no-cache',
        });
      })
    );
  }

  getContactListWithSortedMembers(id: string): Observable<ContactList> {
    return this.query(
      getContactListWithSortedMembers,
      { id },
      (item) => this._contactListBuilder.buildContactList(item),
      'getContactListWithSortedMembers'
    );
  }
}
