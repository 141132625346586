import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { take } from 'rxjs/operators';
import { TabService } from 'src/app/core/services/tab.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import {
  CONTACT_DETAIL_CONTAINER,
  CONTACT_SEARCH_CONTAINER,
} from 'src/app/constants/common.constants';
import { DeviceService } from 'src/app/services/device.service';
import { ContactTableService } from 'src/app/services/contact-table.service';
import { Contact } from 'src/app/model/contacts/contact';

@Component({
  selector: 'app-contact-profile-container',
  templateUrl: './contact-profile-container.component.html',
  styleUrls: ['./contact-profile-container.component.scss'],
})
export class ContactProfileContainerComponent implements OnInit {
  loading = false;

  constructor(
    private _auth: AuthService,
    private _contactApi: ContactApiService,
    private _contactTable: ContactTableService,
    private _logger: LogAndMessageService,
    private _deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.loading = true;
    const email = this._auth.getEmail();
    TabService.getInstance().updateActiveTabLabel('My Profile');
    console.log('EMAIL: ' + email);
    if (email) {
      // lookup contact record via email query
      this._contactApi
        .getFullContactByEmail(email)
        .pipe(take(1))
        .subscribe(
          (contact: Contact) => {
            this._contactTable.setScreenEmbedded(true);
            this._contactTable.setSelected(contact);
            const id = contact.id;
            const oldTab = TabService.getInstance().getActiveTab();
            const newTab = TabService.getInstance().buildNewTab(
              CONTACT_DETAIL_CONTAINER,
              true,
              'My Profile',
              id
            );
            if (this._deviceService.isMobile()) {
              TabService.getInstance().setMobileTab(newTab);
            } else {
              TabService.getInstance().replaceTab(oldTab, newTab);
            }
          },
          (error) => {
            console.error('Error getting contact by email.', error);
            this._logger.translateToErrorMessage({
              headerKey: 'CONTACT.MESSAGES.HEADERS.FAILED_GET_CONTACT',
              bodyKey: 'CONTACT.MESSAGES.ERROR.GET_CONTACT',
            });
            TabService.getInstance().closeTab(
              TabService.getInstance().getActiveIndex()
            );
            this.loading = false;
          }
        );
    } else {
      this.loading = false;
      this._logger.errorLogOnly('email not found.');
      this._logger.translateToErrorMessage({
        headerKey: 'CONTACT.MESSAGES.HEADERS.FAILED_GET_CONTACT',
        bodyKey: 'CONTACT.MESSAGES.ERROR.GET_CONTACT',
      });
      if (this._deviceService.isMobile()) {
        const tab = TabService.getInstance().buildNewTab(
          CONTACT_SEARCH_CONTAINER,
          true
        );
        TabService.getInstance().setMobileTab(tab);
      } else {
        const index = TabService.getInstance().getActiveIndex();
        TabService.getInstance().closeTab(index);
      }
    }
  }
}
