<app-progress-spinner [enabled]="!contactList"></app-progress-spinner>
<p-blockUI [blocked]="saving === true || loading === true"></p-blockUI>
<div
  id="facility-create-form"
  *ngIf="contactList && ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])"
>
  <div id="facility-form-content">
    <div class="no-padding-top-bottom-top-bottom">
      <div class="button-wrapper button-header">
        <div *ngIf="saving">
          <app-progress-spinner [enabled]="saving"></app-progress-spinner>
        </div>
        <div class="button-wrapper button-grouping align-left">
          <p-dropdown
            #dd
            [options]="actions"
            [(ngModel)]="selectedAction"
            (onChange)="actionEvent($event, dd)"
            [ngModelOptions]="{ standalone: true }"
            styleClass="action-drop-down"
          ></p-dropdown>
        </div>
      </div>
    </div>

    <hr id="facility-scroll-divider" />
    <div>
      <div>
        <div id="facility-tab-view">
          <p-tabView
            (onChange)="activeTabChanged($event)"
            [activeIndex]="tabIndex"
          >
            <p-tabPanel header="Contact List">
              <div>
                <div>
                  <app-contact-list-simple-view-container
                    [contactSchedules]="events"
                    [contactList]="contactList"
                    [embedded]="false"
                    [loading]="loading"
                    #sview
                  >
                  </app-contact-list-simple-view-container>
                </div>
              </div>
            </p-tabPanel>
            <p-tabPanel header="Facilities">
              <div class="facilities-tab">
                <div class="dropdowns" *ngIf="canCreate === true">
                  <p-panel
                    [showHeader]="false"
                    styleClass="facility-contact-list-add-panel"
                  >
                    <div>
                      <form
                        [formGroup]="addToFacilityForm"
                        class="facilities-dropdowns"
                      >
                        <div
                          class="no-padding-top-bottom-top-bottom facilities-form-row"
                        >
                          <span class="label text-right"
                            >{{ "LOCATION.LABEL.STATE" | translate }}:</span
                          >
                          <span>
                            <p-autoComplete
                              [suggestions]="filteredStates"
                              (completeMethod)="stateSearch($event)"
                              formControlName="state"
                              [dropdown]="true"
                              appendTo="body"
                              [virtualScroll]="true"
                              [virtualScrollItemSize]="34"
                            ></p-autoComplete>
                          </span>
                        </div>
                        <div
                          class="no-padding-top-bottom-top-bottom facilities-form-row"
                        >
                          <span class="label text-right"
                            >{{ "LOCATION.LABEL.COUNTY" | translate }}:</span
                          >
                          <span>
                            <p-autoComplete
                              [suggestions]="filteredCounties"
                              (completeMethod)="
                                countySearch($event, addToFacilityForm)
                              "
                              formControlName="county"
                              [dropdown]="true"
                              field="county"
                              (onSelect)="
                                selectCounty($event, addToFacilityForm)
                              "
                              appendTo="body"
                              [virtualScroll]="true"
                              [virtualScrollItemSize]="34"
                            >
                            </p-autoComplete>
                          </span>
                        </div>
                        <div
                          class="no-padding-top-bottom-top-bottom facilities-form-row"
                        >
                          <span class="label text-right"
                            >{{ "LOCATION.LABEL.TOWNSHIP" | translate }}:</span
                          >
                          <span>
                            <p-autoComplete
                              [suggestions]="filteredTownships"
                              (completeMethod)="
                                townshipSearch($event, addToFacilityForm)
                              "
                              formControlName="township"
                              [dropdown]="true"
                              field="township"
                              (onSelect)="
                                selectTownship($event, addToFacilityForm)
                              "
                              appendTo="body"
                              [virtualScroll]="true"
                              [virtualScrollItemSize]="34"
                            >
                            </p-autoComplete>
                          </span>
                        </div>
                        <div
                          class="no-padding-top-bottom-top-bottom facilities-form-row"
                        >
                          <span class="label text-right"
                            >{{
                              "LOCATION.LABEL.FACILITY_NAME" | translate
                            }}:</span
                          >
                          <span>
                            <p-autoComplete
                              [suggestions]="filteredFacilities"
                              (completeMethod)="
                                facilitySearch($event, 'facilityName')
                              "
                              formControlName="facility"
                              [dropdown]="true"
                              field="facilityName"
                              (onSelect)="selectFacility($event)"
                              appendTo="body"
                              [virtualScroll]="true"
                              [virtualScrollItemSize]="34"
                            >
                            </p-autoComplete>
                          </span>
                        </div>
                        <div
                          class="no-padding-top-bottom-top-bottom facilities-form-row"
                        >
                          <span class="label text-right"
                            >{{
                              "LOCATION.LABEL.FACILITY_UID" | translate
                            }}:</span
                          >
                          <span>
                            <p-autoComplete
                              [suggestions]="filteredFacilities"
                              (completeMethod)="
                                facilitySearch($event, 'facilityUid')
                              "
                              formControlName="facility"
                              [dropdown]="true"
                              field="facilityUid"
                              (onSelect)="selectFacility($event)"
                              appendTo="body"
                              [virtualScroll]="true"
                              [virtualScrollItemSize]="34"
                            >
                            </p-autoComplete>
                          </span>
                        </div>
                        <div
                          class="no-padding-top-bottom-top-bottom button-row"
                        >
                          <div class="button">
                            <button
                              pButton
                              label="{{
                                'COMMON.LABEL.BUTTONS.ADD' | translate
                              }}"
                              icon="fa fa-fw fa-save"
                              (click)="addContactListToFacility()"
                              [disabled]="!this.addToFacilityForm.valid"
                            ></button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </p-panel>
                </div>
                <div class="facilities-table">
                  <p-dataView
                    #dv
                    [value]="contactListFacilities"
                    filterBy="facilityName"
                  >
                    <p-header>
                      <div class="-clearfix facilities-table-header">
                        <div class="facilities-table-header-content">
                          <div class="filter-container input">
                            <div id="facility-name-wrapper">
                              <input
                                type="search"
                                pInputText
                                placeholder="Search by Facility Name"
                                (keyup)="dataViewKeyUpFilter($event)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-header>
                    <ng-template let-facilities pTemplate="list">
                      <div
                        class="facilities-list-item"
                        *ngFor="let fac of facilities"
                      >
                        <div class="no-padding-top-bottom">
                          <div class="facilities-table-row">
                            <div class="icon">
                              <i
                                class="fa fa-fw fa-minus-circle loc-delete-icon"
                                (click)="deleteContactListFromFacility(fac.id)"
                                *ngIf="canDelete === true"
                              ></i>
                            </div>
                            <div class="no-padding-top-bottom facilities-data">
                              <div class="no-padding-top-bottom data-row">
                                <div class="item">
                                  <div
                                    class="no-padding-top-bottom text-right label"
                                  >
                                    State:
                                  </div>
                                  <div class="no-padding-top-bottom">
                                    {{ fac.state }}
                                  </div>
                                </div>

                                <div class="item">
                                  <div
                                    class="no-padding-top-bottom text-right label"
                                  >
                                    County:
                                  </div>
                                  <div class="no-padding-top-bottom">
                                    {{ fac.county }}
                                  </div>
                                </div>
                                <div class="item">
                                  <div
                                    class="no-padding-top-bottom text-right label"
                                  >
                                    Township:
                                  </div>
                                  <div class="no-padding-top-bottom">
                                    {{ fac.township }}
                                  </div>
                                </div>
                              </div>
                              <div class="no-padding-top-bottom data-row">
                                <div class="item">
                                  <div
                                    class="no-padding-top-bottom text-right label"
                                  >
                                    Name:
                                  </div>
                                  <div class="no-padding-top-bottom">
                                    {{ fac.facilityName }}
                                  </div>
                                </div>
                                <div class="item">
                                  <div
                                    class="no-padding-top-bottom text-right label"
                                  >
                                    ID:
                                  </div>
                                  <div class="no-padding-top-bottom">
                                    {{ fac.facilityUid }}
                                  </div>
                                </div>
                                <div class="item">
                                  <div
                                    class="no-padding-top-bottom text-right label"
                                  >
                                    Type:
                                  </div>
                                  <div class="no-padding-top-bottom">
                                    {{ fac.facilityType }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="save">
                              <button
                                pButton
                                type="button"
                                (click)="openFacility(fac)"
                                label="{{
                                  'COMMON.LABEL.BUTTONS.OPEN' | translate
                                }}"
                              ></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-dataView>
                </div>
              </div>
            </p-tabPanel>
            <p-tabPanel header="Updaters" *ngIf="canViewUpdaters">
              <div id="add-updater-container" *ngIf="canCreate">
                <div>
                  <span class="label"
                    >{{ "CONTACT.LABEL.CONTACT" | translate }}:</span
                  >
                  <span>
                    <p-autoComplete
                      [suggestions]="filteredContacts"
                      (completeMethod)="contactSearch($event)"
                      [(ngModel)]="selectedUpdater"
                      [dropdown]="true"
                      field="label"
                      appendTo="body"
                      [virtualScroll]="true"
                      [virtualScrollItemSize]="34"
                    >
                    </p-autoComplete>
                  </span>
                </div>
                <div class="button-wrapper">
                  <div>
                    <button
                      pButton
                      label="{{ 'COMMON.LABEL.BUTTONS.ADD' | translate }}"
                      icon="fa fa-fw fa-save"
                      (click)="addContactAsUpdater()"
                    ></button>
                  </div>
                </div>
              </div>
              <div class="break"></div>
              <div
                *ngFor="let u of contactList.updaters"
                class="updater-container"
              >
                <p-panel
                  [showHeader]="false"
                  *ngIf="u.dirtyStatus !== DirtyStatus.DELETED"
                >
                  <div>
                    <div>
                      <i
                        class="fa fa-fw fa-minus-circle updater-delete-icon"
                        (click)="removeContactAsUpdater(u.id)"
                      ></i
                      >{{ u?.label }}
                    </div>
                  </div>
                </p-panel>
              </div>
            </p-tabPanel>
            <p-tabPanel header="Schedule">
              <div class="schedule">
                <full-calendar [options]="calendarOptions"></full-calendar>
                <app-schedule-form
                  *ngIf="canSchedule"
                  [isVisible]="scheduleFormVisible"
                  (scheduleFormHidden)="onScheduleFormHidden()"
                  (scheduleFormSubmitted)="onScheduleFormSubmitted($event)"
                  [minDateTime]="today"
                  (scheduleDeleted)="onScheduleDeleted($event)"
                  [scheduleToEdit]="selectedSchedule"
                  [formType]="scheduleFormType"
                  [isLoading]="loading"
                  [contactList]="contactList"
                  [contacts]="memberContactArray"
                  #scheduleForm
                ></app-schedule-form>
              </div>
              <br /><br />
            </p-tabPanel>
            <p-tabPanel header="Schedule Import/Export" *ngIf="canSchedule">
              <app-schedule-import
                [contactList]="contactList"
                (scheduleImported)="onScheduleImported()"
                (scheduleExported)="onScheduleExport()"
              ></app-schedule-import>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="
    contactList &&
    ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])
  "
>
  <app-contact-list-simple-view-container [contactList]="contactList">
  </app-contact-list-simple-view-container>
</div>

<app-audit-dialog-container
  [displayDialog]="displayAuditDialog"
  [auditHistory]="history"
  (dialogHiddenEvent)="auditDialogHidden()"
  [loading]="auditLoading"
></app-audit-dialog-container>
