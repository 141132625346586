import { Injectable } from '@angular/core';
import { ContactList } from '../model/contacts/contact-list';
import { BaseTableService } from './base-table.service';

@Injectable({
  providedIn: 'root'
})
export class ContactListTableService extends BaseTableService<ContactList> {
  constructor() {
    super();
  }
}
