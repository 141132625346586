import { gql } from 'graphql-tag'

export const editContactListSchedule = gql`
mutation editContactListSchedule($input: ContactListScheduleInput){
    editContactListSchedule(input: $input){
        id
        isPrimary
        startTime
        endTime
        timezone
    }
}
`