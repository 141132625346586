import gql from 'graphql-tag';

export const getContactLists = gql`
query getContactLists {
  getContactLists {
    id
    createdAt
    updatedAt
    deletedAt
    MemberCodes{
      id
      code
    }
    name
    ContactListMembers {
      id
      ContactId
      order
    }
    ContactListUpdaters {
      id
      ContactId
    }
    Facilities {
      id
      Locations {
        id
        state
        county
        township
      }
    }
  }
}`;
