<div id="contact-information-container">
  <form [formGroup]="form" id="contact-create-form">
    <div id="general-info">
      <p-panel
        header="{{ 'CONTACT.LABEL.GENERAL_INFO' | translate }}"
        [toggleable]="true"
        toggler="header"
        [collapsed]="startCollapsed"
      >
        <div>
          <div class="row">
            <div class="row-item">
              <div class="row-label contact-form-label">
                {{ "CONTACT.LABEL.FIRST_NAME" | translate
                }}<span class="required">*</span>
              </div>
              <div class="row-input">
                <input type="text" pInputText formControlName="firstName" />
                <div
                  class="field-message"
                  *ngIf="form.dirty && !form.controls['firstName'].valid"
                >
                  First Name is required.
                </div>
              </div>
            </div>
            <div class="row-item">
              <div class="row-label contact-form-label">
                {{ "CONTACT.LABEL.LAST_NAME" | translate
                }}<span class="required">*</span>
              </div>
              <div class="row-input">
                <input type="text" pInputText formControlName="lastName" />
                <div
                  class="field-message"
                  *ngIf="form.dirty && !form.controls['lastName'].valid"
                >
                  Last Name is required.
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="row-item">
              <div class="row-label contact-form-label">
                {{ "CONTACT.LABEL.USUAL_FIRST_NAME" | translate }}
              </div>
              <div class="row-input">
                <input type="text" pInputText formControlName="nickName" />
              </div>
            </div>

            <div class="row-item">
              <div class="row-label contact-form-label">
                {{ "CONTACT.LABEL.JOB_ROLE" | translate
                }}<span class="required">*</span>
              </div>
              <div class="row-input">
                <p-dropdown
                  [options]="availableJobRoles"
                  formControlName="jobRole"
                  [filter]="true"
                  [style]="{ width: '99%' }"
                >
                </p-dropdown>
                <div
                  class="field-message"
                  *ngIf="form.dirty && !form.controls['jobRole'].valid"
                >
                  Job Role is required.
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="row-item">
              <div class="row-label contact-form-label">
                {{ "CONTACT.LABEL.SUPERVISOR" | translate
                }}<span class="required">*</span>
              </div>
              <div class="row-input">
                <p-autoComplete
                  [suggestions]="filteredContacts"
                  (completeMethod)="this.supervisorSearchEvent.emit($event)"
                  formControlName="supervisor"
                  [dropdown]="true"
                  field="name"
                  placeholder="Select Contact"
                  (onSelect)="this.selectSupervisorEvent.emit($event)"
                  appendTo="body"
                  [forceSelection]="true"
                  [style]="{ width: '99%', 'margin-left': '0.2vw' }"
                >
                </p-autoComplete>
                <div
                  class="field-message"
                  *ngIf="form.dirty && !form.controls['supervisor'].valid"
                >
                  Supervisor is required.
                </div>
              </div>
              <div class="input-icon">
                <i
                  class="fa fa-fw fa-id-card"
                  (click)="this.goToContactEvent.emit('supervisor')"
                ></i>
              </div>
            </div>
            <div class="row-item">
              <div
                class="row-label contact-form-label"
                *ngIf="showWhenUnavailable === true"
              >
                {{ "CONTACT.LABEL.WHEN_UNAVAILABLE" | translate }}
              </div>
              <div class="row-input" *ngIf="showWhenUnavailable">
                <p-autoComplete
                  [suggestions]="filteredContacts"
                  (completeMethod)="
                    this.whenUnavailableSearchEvent.emit($event)
                  "
                  formControlName="whenUnavailable"
                  [dropdown]="true"
                  field="name"
                  appendTo="body"
                  [forceSelection]="true"
                  [style]="{ width: '99%', 'margin-left': '0.2vw' }"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="34"
                  placeholder="Select Contact"
                ></p-autoComplete>
              </div>
              <div class="input-icon" *ngIf="showWhenUnavailable">
                <i
                  class="fa fa-fw fa-id-card"
                  (click)="this.goToContactEvent.emit('unavailable')"
                ></i>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="row-item">
              <div class="row-label contact-form-label">
                {{ "CONTACT.LABEL.COMPANY" | translate
                }}<span class="required">*</span>
              </div>
              <div class="row-input">
                <p-dropdown
                  [options]="availableCompanies"
                  formControlName="company"
                  [style]="{ width: '99%' }"
                >
                </p-dropdown>
                <div
                  class="field-message"
                  *ngIf="form.dirty && !form.controls['company'].valid"
                >
                  Company is required.
                </div>
              </div>
            </div>

            <div class="row-item">
              <div class="row-label contact-form-label">
                {{ "CONTACT.LABEL.WORK_EMAIL" | translate
                }}<span class="required">*</span>
              </div>
              <div class="row-input">
                <input type="text" pInputText formControlName="email" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="row-item">
              <div class="row-label contact-form-label">
                {{ "CONTACT.LABEL.CITY" | translate
                }}<span class="required">*</span>
              </div>
              <div class="row-input">
                <input type="text" pInputText formControlName="city" />
                <div
                  class="field-message"
                  *ngIf="form.dirty && !form.controls['city'].valid"
                >
                  City is required.
                </div>
              </div>
            </div>
            <div class="row-item"></div>
          </div>
        </div>
      </p-panel>
    </div>

    <div id="unavailable-date-range">
      <p-panel
        header="{{
          'CONTACT.LABEL.UNAVAILABLE_DATE_RANGE' | translate
        }} (Active & Upcoming)"
        [toggleable]="true"
        toggler="header"
        [collapsed]="startCollapsed"
      >
        <div class="panel">
          <div class="no-padding" *ngIf="writeAccess">
            <div style="margin-bottom: 8px">
              <button
                pButton
                [label]="modifyButtonLabel"
                type="button"
                [icon]="modifyButtonIcon"
                (click)="openAvailability()"
              ></button>
            </div>
          </div>
          <div
            id="divAvailability"
            *ngIf="isShown"
            style="
              border: 2px solid #efefef;
              width: 100%;
              padding: 5px;
              margin: 8px 0;
            "
          >
            <div class="container">
              <div class="row align-items-start dates">
                <div class="col" *ngIf="writeAccess">
                  <div>{{ "CONTACT.LABEL.START" | translate }}</div>
                  <div>
                    <p-calendar
                      [showTime]="true"
                      [(ngModel)]="startTime"
                      [showSeconds]="false"
                      [minDate]="minDate"
                      [ngModelOptions]="{ standalone: true }"
                      [disabled]="disableStart"
                      [defaultDate]="defaultStartDate"
                      appendTo="body"
                      [hideOnDateTimeSelect]="true"
                    >
                    </p-calendar>
                  </div>
                </div>
                <div class="col" *ngIf="writeAccess">
                  <div>{{ "CONTACT.LABEL.END" | translate }}</div>
                  <div>
                    <p-calendar
                      [showTime]="true"
                      [(ngModel)]="endTime"
                      [showSeconds]="false"
                      [minDate]="minDate"
                      [ngModelOptions]="{ standalone: true }"
                      [defaultDate]="defaultEndDate"
                      appendTo="body"
                      [hideOnDateTimeSelect]="true"
                    >
                    </p-calendar>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <div class="avail-container" *ngIf="writeAccess">
              <div class="button">
                <button
                  pButton
                  label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
                  type="button"
                  icon="fa fa-fw fa-save"
                  (click)="modifyAvailability()"
                ></button>
              </div>
              <div class="button">
                <button
                  pButton
                  label="{{ 'COMMON.LABEL.BUTTONS.CLEAR' | translate }}"
                  type="button"
                  icon="fa fa-fw fa-eraser"
                  (click)="clearAvailability()"
                ></button>
              </div>
              <div class="button">
                <button
                  pButton
                  label="{{ 'COMMON.LABEL.BUTTONS.CLOSE' | translate }}"
                  type="button"
                  icon="fa fa-fw fa-close"
                  (click)="openAvailability()"
                ></button>
              </div>
            </div>
          </div>
          <div>
            <p-scrollPanel styleClass="contact-availability-scroll-panel">
              <p-table
                [value]="contact.availabilities"
                sortField="start"
                [sortOrder]="1"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th width="28px">&nbsp;</th>
                    <th width="28px">&nbsp;</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-avail>
                  <tr>
                    <td>
                      <div *ngIf="writeAccess && canBeModified(avail)">
                        <i
                          class="fa fa-fw fa-edit avail-edit-icon"
                          (click)="this.updateAvailabilityEvent.emit(avail.id)"
                        ></i>
                      </div>
                    </td>
                    <td>
                      <div
                        *ngIf="
                          writeAccess &&
                          ifDeviceMatches([
                            DeviceSize.MD,
                            DeviceSize.LG,
                            DeviceSize.XL
                          ]) &&
                          canBeModified(avail)
                        "
                      >
                        <i
                          class="fa fa-fw fa-minus-circle avail-delete-icon"
                          (click)="this.deleteAvailabilityEvent.emit(avail.id)"
                        ></i>
                      </div>
                    </td>
                    <td>{{ avail.start | date : "short" }}</td>
                    <td>{{ avail.end | date : "short" }}</td>
                  </tr>
                </ng-template>
              </p-table>
            </p-scrollPanel>
          </div>
        </div>
      </p-panel>
    </div>
    <br />
    <div id="phone-numbers">
      <p-panel
        header="{{ 'CONTACT.LABEL.PHONE_NUMBERS' | translate }}"
        [toggleable]="true"
        toggler="header"
        [collapsed]="startCollapsed"
      >
        <div class="panel">
          <div class="row">
            <div class="row-item">
              <div class="row-label contact-form-label">
                {{ "CONTACT.LABEL.BUSINESS_PHONE" | translate
                }}<span class="required">*</span>
              </div>
              <div class="row-input">
                @if (maskButtonVisible.get('bp')) {
                <p-button (click)="setMask('US', 'bp')" class="mask-button">
                  {{ "COMMON.LABEL.BUTTONS.USCA" | translate }}
                </p-button>
                <p-button (click)="setMask('MX', 'bp')" class="mask-button">
                  {{ "COMMON.LABEL.BUTTONS.MX" | translate }}
                </p-button>
                }
                <p-inputMask
                  [mask]="phoneMasks.get('bp')"
                  id="businessPhone"
                  formControlName="businessPhone"
                  placeholder="{{
                    'CONTACT.LABEL.ENTER_BUSINESS_PHONE' | translate
                  }}"
                  (onFocus)="showMaskButtons('bp')"
                >
                </p-inputMask>
                <span
                  *ngIf="
                    this.contact.afterHoursContactMethod
                      ? 'OFFICE' === this.contact.afterHoursContactMethod.value
                      : false
                  "
                >
                  <i
                    class="fa fa-fw fa-check-circle after-hours-contact-method"
                  ></i>
                  {{ "CONTACT.LABEL.PREFERRED" | translate }}
                </span>
                <div
                  class="field-message"
                  *ngIf="form.dirty && !form.controls['businessPhone'].valid"
                >
                  Business Phone is required.
                </div>
              </div>
            </div>

            <div class="row-item">
              <div class="row-label label contact-form-label">
                {{ "CONTACT.LABEL.BUSINESS_MOBILE" | translate }}
              </div>

              <div class="row-input">
                @if (maskButtonVisible.get('bm')) {
                <p-button (click)="setMask('US', 'bm')" class="mask-button">
                  {{ "COMMON.LABEL.BUTTONS.USCA" | translate }}
                </p-button>
                <p-button (click)="setMask('MX', 'bm')" class="mask-button">
                  {{ "COMMON.LABEL.BUTTONS.MX" | translate }}
                </p-button>
                }
                <p-inputMask
                  [mask]="phoneMasks.get('bm')"
                  id="businessMobile"
                  formControlName="businessMobile"
                  placeholder="{{
                    'CONTACT.LABEL.ENTER_BUSINESS_MOBILE' | translate
                  }}"
                  (onFocus)="showMaskButtons('bm')"
                >
                </p-inputMask>
                <span
                  *ngIf="
                    this.contact.afterHoursContactMethod
                      ? 'CELLULAR' ===
                        this.contact.afterHoursContactMethod.value
                      : false
                  "
                >
                  <i
                    class="fa fa-fw fa-check-circle after-hours-contact-method"
                  ></i>
                  {{ "CONTACT.LABEL.PREFERRED" | translate }}
                </span>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="canViewPII">
            <div class="row-item">
              <div class="row-label contact-form-label">
                {{ "CONTACT.LABEL.PERSONAL_MOBILE" | translate }}
              </div>
              <div class="row-input">
                @if (maskButtonVisible.get('pm')) {
                <p-button (click)="setMask('US', 'pm')" class="mask-button">
                  {{ "COMMON.LABEL.BUTTONS.USCA" | translate }}
                </p-button>
                <p-button (click)="setMask('MX', 'pm')" class="mask-button">
                  {{ "COMMON.LABEL.BUTTONS.MX" | translate }}
                </p-button>
                }
                <p-inputMask
                  [mask]="phoneMasks.get('pm')"
                  id="personalMobile"
                  formControlName="personalMobile"
                  placeholder="{{
                    'CONTACT.LABEL.ENTER_PERSONAL_MOBILE' | translate
                  }}"
                  (onFocus)="showMaskButtons('pm')"
                ></p-inputMask>
                <span
                  *ngIf="
                    this.contact.afterHoursContactMethod
                      ? 'PERSONAL CELL' ===
                        this.contact.afterHoursContactMethod.value
                      : false
                  "
                >
                  <i
                    class="fa fa-fw fa-check-circle after-hours-contact-method"
                  ></i>
                  {{ "CONTACT.LABEL.PREFERRED" | translate }}
                </span>
              </div>
            </div>

            <div class="row-item">
              <div class="row-label contact-form-label">
                {{ "CONTACT.LABEL.HOME" | translate }}
              </div>
              <div class="row-input">
                @if (maskButtonVisible.get('home')) {
                <p-button (click)="setMask('US', 'home')" class="mask-button">
                  {{ "COMMON.LABEL.BUTTONS.USCA" | translate }}
                </p-button>
                <p-button (click)="setMask('MX', 'home')" class="mask-button">
                  {{ "COMMON.LABEL.BUTTONS.MX" | translate }}
                </p-button>
                }
                <p-inputMask
                  [mask]="phoneMasks.get('home')"
                  id="home"
                  formControlName="home"
                  placeholder="{{ 'CONTACT.LABEL.ENTER_HOME' | translate }}"
                  (onFocus)="showMaskButtons('home')"
                >
                </p-inputMask>
                <span
                  *ngIf="
                    this.contact.afterHoursContactMethod
                      ? 'HOME' === this.contact.afterHoursContactMethod.value
                      : false
                  "
                >
                  <i
                    class="fa fa-fw fa-check-circle after-hours-contact-method"
                  ></i>
                  {{ "CONTACT.LABEL.PREFERRED" | translate }}
                </span>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="canViewPII">
            <div class="row-item">
              <div class="row-label contact-form-label">
                {{ "CONTACT.LABEL.OTHER" | translate }}
              </div>
              <div class="row-input">
                @if (maskButtonVisible.get('other')) {
                <p-button (click)="setMask('US', 'other')" class="mask-button">
                  {{ "COMMON.LABEL.BUTTONS.USCA" | translate }}
                </p-button>
                <p-button (click)="setMask('MX', 'other')" class="mask-button">
                  {{ "COMMON.LABEL.BUTTONS.MX" | translate }}
                </p-button>
                }
                <p-inputMask
                  [mask]="phoneMasks.get('other')"
                  id="pager"
                  formControlName="other"
                  placeholder="{{ 'CONTACT.LABEL.ENTER_OTHER' | translate }}"
                  styleClass="pager-control"
                  (onFocus)="showMaskButtons('other')"
                ></p-inputMask>
                <input
                  type="text"
                  pInputText
                  formControlName="note"
                  placeholder="{{ 'CONTACT.LABEL.ENTER_NOTE' | translate }}"
                  class="other-note"
                />
                <span
                  *ngIf="
                    this.contact.afterHoursContactMethod
                      ? 'OTHER' === this.contact.afterHoursContactMethod.value
                      : false
                  "
                >
                  <i
                    class="fa fa-fw fa-check-circle after-hours-contact-method"
                  ></i>
                  {{ "CONTACT.LABEL.PREFERRED" | translate }}
                </span>
              </div>
            </div>

            <div class="row-item">
              <div class="row-label contact-form-label">
                {{ "CONTACT.LABEL.AFTER_HOURS_CONTACT_METHOD" | translate }}
              </div>
              <div class="row-input">
                <p-dropdown
                  [options]="availableContactMethods"
                  formControlName="afterHoursContactMethod"
                ></p-dropdown>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="row-item">
              <div class="row-label contact-form-label">
                {{ "CONTACT.LABEL.SPEED_DIAL" | translate }}
              </div>
              <div class="row-input">
                <input
                  type="text"
                  pInputText
                  formControlName="speedDial"
                  class="speed-dial"
                />
              </div>
            </div>
            <div class="row-item"></div>
          </div>
        </div>
      </p-panel>
    </div>
    <br />
  </form>
  <br />
</div>
