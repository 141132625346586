import gql from 'graphql-tag';

export const queryForExternalContactLists = gql`
query queryForExternalContactLists($limit: Int, $page: Int, $query: ExternalContactListSortOrQuery, $sort: ExternalContactListSortOrQuery) {
  queryForExternalContactLists(limit: $limit, page: $page, query: $query, sort: $sort) {
    items {
      id
      name
    }
    totalRecords
  }
}`;
