import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsRoutingModule } from './contacts-routing.module';
import { ContactsSearchContainerComponent } from './containers/contacts-search/contacts-search-container.component';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../core/core.module';
import { ContactDetailsContainerComponent } from './containers/contact-details/contact-details-container.component';
import { ContactListCardComponent } from './components/contact-list-card.component';
import { ContactInformationFormComponent } from './components/contact-information-form.component';
import { ContactAssociateFacilitiesComponent } from './components/contact-associate-facilities.component';
import { ContactListSearchContainerComponent } from './containers/contact-list-search/contact-list-search-container.component';
import { ContactListSimpleViewContainerComponent } from './containers/contact-list-simple-view/contact-list-simple-view-container.component';
import { ContactListDetailViewContainerComponent } from './containers/contact-list-detail-view/contact-list-detail-view-container.component';
import { ContactListCreateContainerComponent } from './containers/contact-list-create/contact-list-create-container.component';
import { ContactMobileFilterComponent } from './components/contact-mobile-filter.component';
import { ContactProfileContainerComponent } from './containers/contact-profile/contact-profile-container.component';
import { ContactListMobileFilterComponent } from './components/contact-list-mobile-filter.component';
import { ExternalContactListSearchContainerComponent } from './containers/external-contact-list-search/external-contact-list-search-container.component';
import { ExternalContactListDetailsContainerComponent } from './containers/external-contact-list-details/external-contact-list-details-container.component';
import { ExternalContactListMobileFilterComponent } from './components/external-contact-list-mobile-filter.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ScheduleFormComponent } from './components/schedule-form/schedule-form.component';
import { ScheduleImportComponent } from './components/schedule-import/schedule-import.component';
import { FileUploadModule } from 'primeng/fileupload';
import { MemberCodesFormComponent } from './components/member-codes-form/member-codes-form.component';

@NgModule({
  declarations: [
    ContactsSearchContainerComponent,
    ContactDetailsContainerComponent,
    ContactListCardComponent,
    ContactInformationFormComponent,
    ContactAssociateFacilitiesComponent,
    ContactListSearchContainerComponent,
    ContactListSimpleViewContainerComponent,
    ContactListDetailViewContainerComponent,
    ContactListCreateContainerComponent,
    ContactMobileFilterComponent,
    ContactProfileContainerComponent,
    ContactListMobileFilterComponent,
    ExternalContactListSearchContainerComponent,
    ExternalContactListDetailsContainerComponent,
    ExternalContactListMobileFilterComponent,
    ScheduleFormComponent,
    ScheduleImportComponent,
  ],
  imports: [
    CommonModule,
    ContactsRoutingModule,
    CoreModule,
    FullCalendarModule,
    FileUploadModule,
    TranslateModule.forChild({}),
    MemberCodesFormComponent,
  ],
  exports: [
    ContactsSearchContainerComponent,
    ContactDetailsContainerComponent,
    ContactListCardComponent,
    ContactInformationFormComponent,
    ContactAssociateFacilitiesComponent,
    ContactListSearchContainerComponent,
    ContactListSimpleViewContainerComponent,
    ContactListDetailViewContainerComponent,
    ContactListCreateContainerComponent,
    ContactMobileFilterComponent,
    ContactProfileContainerComponent,
    ContactListMobileFilterComponent,
    ExternalContactListSearchContainerComponent,
    ExternalContactListDetailsContainerComponent,
    ExternalContactListMobileFilterComponent,
  ],
})
export class ContactsModule {}
