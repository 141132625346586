import gql from 'graphql-tag'; 

export const getContactListSchedules = gql`
query getContactListSchedules($contact_list_id: String){
    getContactListSchedules(contact_list_id: $contact_list_id){
        id 
        isPrimary
        startTime
        endTime
        timezone
        version
        contact{
            adAccountName
            firstName
            lastName
            id
        }
    }
}`