import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  DoCheck,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { reject } from 'lodash';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { TabView } from 'primeng/tabview';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { VIEW_AUDIT } from 'src/app/constants/action-constants';
import {
  CONTACT_DETAIL_CONTAINER,
  CONTACT_LIST_DETAIL_CONTAINER,
  FACILITY_DETAIL_CONTAINER,
  CONTACT_SEARCH_CONTAINER,
} from 'src/app/constants/common.constants';
import {
  CONTACT_TAB_PREFIX,
  NEW_CONTACT,
} from 'src/app/constants/contact-constants';
import { DetailsContainer } from 'src/app/core/containers/details-container';
import {
  buildAuditHistory,
  dynamicSort,
  isNullOrEmpty,
  onlyUnique,
} from 'src/app/core/functions/common-functions';
import { TabService } from 'src/app/core/services/tab.service';
import { BreadCrumb } from 'src/app/model/common/bread-crumb';
import { DirtyStatus } from 'src/app/model/common/dirty-status';
import { Availability } from 'src/app/model/contacts/availability';
import { Contact } from 'src/app/model/contacts/contact';
import { Facility } from 'src/app/model/locations/facility';
import { FacilityTableService } from 'src/app/services/facility-table.service';
import { AuthService } from 'src/app/services/auth.service';
import { ContactListApiService } from 'src/app/services/contact-list-api.service';
import { DeviceService } from 'src/app/services/device.service';
import { FacilityApiService } from 'src/app/services/facility-api.service';
import { FacilityBuilderService } from 'src/app/services/facility-builder.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LocationApiService } from 'src/app/services/location-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { v4 as uuid } from 'uuid';
import { ContactApiService } from '../../../services/contact-api.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { ContactBuilderService } from '../../../services/contact-builder.service';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { ContactTableService } from 'src/app/services/contact-table.service';
import { ContactListTableService } from 'src/app/services/contact-list-table.service';
import { isNullOrUndefined } from 'src/app/utils/utils';
import { ContactInformationFormComponent } from '../../components/contact-information-form.component';

@Component({
  selector: 'app-contact-details-container',
  templateUrl: './contact-details-container.component.html',
  styleUrls: ['./contact-details-container.component.scss'],
})
export class ContactDetailsContainerComponent
  extends DetailsContainer
  implements OnInit, OnDestroy, DoCheck, AfterContentChecked
{
  @ViewChild('tv', { static: false }) tabView: TabView;
  @ViewChild('cif') contactInformationForm: ContactInformationFormComponent;

  DirtyStatus = DirtyStatus;

  contact: Contact;
  /**
   * Breacrumb to show the current selected offer.
   */
  contactBreadCrumb: BreadCrumb;

  /**
   * Breadcrumb to show the "Back To Search Result" text.
   */
  backToSearchResult: BreadCrumb;

  availableCompanies: any[];
  availableCountries: any[];
  availableJobRoles: any[];
  filteredContacts: SelectItem[];
  availableContactMethods: SelectItem[];
  validationMessages: any;
  validationParams: any;
  breadCrumbSub: Subscription;
  sub: Subscription;
  saving = false;
  startTime: Date;
  endTime: Date;
  selectedAvailability: Availability;
  addLabel: string;
  updateLabel: string;
  modifyAvailabilityButtonLabel: string;
  modifyAvailabilityIcon: string;
  addIcon = 'fa fa-fw fa-plus';
  updateIcon = 'fa fa-fw fa-save';
  isUnavailable = false;
  deleteMsg: string;
  okBtnLabel: string;
  cancelBtnLabel: string;
  deleteHeader: string;

  facilities: Facility[];
  facilitiesLoaded = false;

  canEdit = false;
  canEditOwn = false;
  canEditDirectReports = false;
  canCreate = false;
  allowedToModifyIds: string[];
  canViewPII = false;

  afterHoursSub: Subscription;
  personalMobileSub: Subscription;
  businessPhoneSub: Subscription;
  businessMobileSub: Subscription;
  homeSub: Subscription;
  pagerSub: Subscription;
  speedDialSub: Subscription;
  prevAfterHours: any;
  showWhenUnavailable = false;

  actions: SelectItem[];
  selectedAction: any = null;
  startCollapsed = true;
  tabIndex = 0;

  constructor(
    protected _translateService: TranslateService,
    private _breadCrumbService: BreadCrumbBuilderService,
    protected _deviceService: DeviceService,
    private _fb: UntypedFormBuilder,
    protected _contactApi: ContactApiService,
    protected _contactTable: ContactTableService,
    private _contactBuilder: ContactBuilderService,
    private _cdRef: ChangeDetectorRef,
    private _logAndMessage: LogAndMessageService,
    protected _confirmationService: ConfirmationService,
    protected _facilityApi: FacilityApiService,
    private _facilityBuilder: FacilityBuilderService,
    private _contactListApi: ContactListApiService,
    private _contactListTable: ContactListTableService,
    private _auth: AuthService,
    private _authApi: AuthApiService,
    protected _locationApi: LocationApiService,
    protected _facilityTableService: FacilityTableService,
    private _loader: LoadingService
  ) {
    super(
      _translateService,
      _deviceService,
      _confirmationService,
      _locationApi,
      _contactApi,
      _facilityTableService,
      _facilityApi
    );
  }

  ngOnInit() {
    this.setupTabClosingSubscription();
    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.subscribe((event) => {
        this.initialize();
      });
    }
  }

  initialize() {
    const id = this.id;
    this.contact = this._contactTable.getSelected();
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([
      'EditAllProfiles',
    ]);
    this.canEditOwn = this._authApi.doesUserHaveAllClaimsFromList([
      'EditOwnProfile',
    ]);
    this.canEditDirectReports = this._authApi.doesUserHaveAllClaimsFromList([
      'EditDirectReportsProfile',
    ]);
    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      'CreateContact',
    ]);

    if (this.contact) {
      this.setupScreen();
    } else if (id && id !== 'newContact') {
      this._contactApi
        .getContactById(id)
        .pipe(take(1))
        .subscribe(({ data }) => {
          const clone = Object.assign({}, data);
          this.contact = this._contactBuilder.buildContact(clone.getContact);
          this.doCheckComplete = false;
          this.setupScreen();
        });
    } else {
      const validations = this._contactBuilder.buildContactValidationMessages();
      this.validationMessages = validations.messages;
      this.validationParams = validations.params;

      this.contact = this._contactBuilder.buildNewContact();

      this.setupLabels();
      this.setupBreadCrumbAndSubscriptions();

      if (!this._contactTable.isScreenEmbedded()) {
        if (isNullOrUndefined(this.contact.id)) {
          TabService.getInstance().updateActiveTabLabel(NEW_CONTACT);
        }
      }

      this.buildForm();
      this.loading = false;
      this._cdRef.markForCheck();
    }
    this.updateActionsList();
  }

  setupLabels(): void {
    const deleteMsg$ = this._translateService.get(
      'CONTACT.MESSAGES.CONFIRMATION.DELETE_AVAILABILITY'
    );
    const ok$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
    const cancel$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
    const header$ = this._translateService.get(
      'CONTACT.MESSAGES.HEADERS.DELETE_AVAILABILITY'
    );
    const add$ = this._translateService.get('COMMON.LABEL.BUTTONS.ADD');
    const update$ = this._translateService.get('COMMON.LABEL.BUTTONS.UPDATE');
    forkJoin([add$, update$, deleteMsg$, ok$, cancel$, header$]).subscribe(
      (messages) => {
        this.addLabel = messages[0];
        this.updateLabel = messages[1];
        this.modifyAvailabilityButtonLabel = this.addLabel;
        this.modifyAvailabilityIcon = this.addIcon;
        this.deleteMsg = messages[2];
        this.okBtnLabel = messages[3];
        this.cancelBtnLabel = messages[4];
        this.deleteHeader = messages[5];
      }
    );
  }

  setupBreadCrumbAndSubscriptions(): void {
    if (this._deviceService.isMobile()) {
      // setup the bread crumb.
      this._translateService
        .get('CONTACT.SCREEN.CONTACT')
        .subscribe((label) => {
          this._breadCrumbService.resetAndAddBreadCrumb(
            new BreadCrumb(label, null, true)
          );
          if (
            !isNullOrUndefined(this.contact) &&
            !isNullOrUndefined(this.contact.firstName)
          ) {
            this.contactBreadCrumb = new BreadCrumb(
              this.contact.firstName + ' ' + this.contact.lastName,
              null,
              true,
              this.contact.id
            );
            this._breadCrumbService.addBreadCrumb(this.contactBreadCrumb);
          } else {
            this.contactBreadCrumb = new BreadCrumb('New Contact', null, true);
            this._breadCrumbService.addBreadCrumb(this.contactBreadCrumb);
          }
        });

      // keep track of bread crumb events so users can navigate back to other contacts.
      this.breadCrumbSub =
        this._breadCrumbService.breadCrumbSelectedEvent.subscribe((event) => {
          this._breadCrumbService.removeBreadCrumb(this.contactBreadCrumb);
          const tab = TabService.getInstance().buildNewTab(
            CONTACT_SEARCH_CONTAINER,
            true
          );
          TabService.getInstance().setMobileTab(tab);
        });
    }

    this._contactApi
      .loadAvailableCompanies()
      .pipe(take(1))
      .subscribe(({ data }) => {
        this.availableCompanies = [
          { label: 'Select Option', value: null },
          ...data.getAvailableCompanies
            .sort(dynamicSort('name', 1))
            .filter(
              (j) =>
                j.enabled === true ||
                (this.contact.companyItem &&
                  this.contact.companyItem.value === j.id)
            )
            .map((c) => ({ label: c.name, value: c.id })),
        ];
      });

    this._contactApi
      .loadAvailableJobRoles()
      .pipe(take(1))
      .subscribe(({ data }) => {
        this.availableJobRoles = [
          { label: 'Select Option', value: null },
          ...data.getAvailableJobRoles
            .sort(dynamicSort('name', 1))
            .filter(
              (j) =>
                j.enabled === true ||
                (this.contact.jobRoleItem &&
                  this.contact.jobRoleItem.value === j.id)
            )
            .map((j) => ({ label: j.name, value: j.id })),
        ];
      });

    // TODO: Translate and see if there is a better way to do this.
    this.availableContactMethods = [
      { label: 'Select Option', value: '' },
      { label: 'Business Phone', value: 'OFFICE' },
      { label: 'Business Mobile', value: 'CELLULAR' },
      { label: 'Home', value: 'HOME' },
      { label: 'Personal Mobile', value: 'PERSONAL CELL' },
      { label: 'Speed Dial', value: 'SPEED DIAL' },
      { label: 'Other', value: 'OTHER' },
    ];
  }

  setupScreen() {
    const validations = this._contactBuilder.buildContactValidationMessages();
    this.validationMessages = validations.messages;
    this.validationParams = validations.params;

    this.setupLabels();

    // we dont' have a contact, navigate back to search grid
    if (!this.contact) {
      this._logAndMessage.errorLogOnly('Contact ojbect is null');
      if (this._deviceService.isMobile()) {
        const tab = TabService.getInstance().buildNewTab(
          CONTACT_SEARCH_CONTAINER,
          true
        );
        TabService.getInstance().setMobileTab(tab);
      } else {
        const index = TabService.getInstance().getActiveIndex();
        TabService.getInstance().closeTab(index);
      }
    } else {
      // Set tab header if coming from the contact screen. This will
      // not update if the user is viewing their profile page.
      if (
        !this._contactTable.isScreenEmbedded() &&
        !this._deviceService.isMobile()
      ) {
        TabService.getInstance().updateActiveTabLabel(
          CONTACT_TAB_PREFIX + this.contact.fullName
        );
      }

      // check availablilities so we can flag the user as unavailable if needed.
      this.checkAvailability();

      this.setupBreadCrumbAndSubscriptions();

      this.findReportingChain();
    }

    this.buildForm();
    this.loading = false;
    this._cdRef.markForCheck();
    setTimeout(() => {
      this.contactInformationForm?.setMasksBasedOnFieldValues();
    }, 50);
  }

  ngOnDestroy(): void {
    if (this.breadCrumbSub) {
      this.breadCrumbSub.unsubscribe();
    }

    if (this.valueSub) {
      this.valueSub.unsubscribe();
    }

    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.personalMobileSub) {
      this.personalMobileSub.unsubscribe();
    }

    if (this.afterHoursSub) {
      this.afterHoursSub.unsubscribe();
    }

    if (this.businessMobileSub) {
      this.businessMobileSub.unsubscribe();
    }

    if (this.businessPhoneSub) {
      this.businessPhoneSub.unsubscribe();
    }

    if (this.homeSub) {
      this.homeSub.unsubscribe();
    }

    if (this.pagerSub) {
      this.pagerSub.unsubscribe();
    }

    if (this.tabClosingSub) {
      this.tabClosingSub.unsubscribe();
    }
  }

  goToContact(field) {
    let id = null;
    if (field === 'supervisor') {
      id = this.contact.reportsToId;
    } else if (field === 'manager') {
      id = this.contact.managerId;
    } else if (field === 'director') {
      id = this.contact.directorId;
    } else if (field === 'unavailable') {
      id = this.contact.whenUnavailableId;
    }

    if (!isNullOrUndefined(id)) {
      this._contactTable.setScreenEmbedded(false);
      if (this.canDeactivate() === true) {
        this.findContact(id);
      } else {
        (this.canDeactivate() as Observable<boolean>).subscribe((result) => {
          if (result === true) {
            this.findContact(id);
          }
        });
      }
    }
  }

  saveContact() {
    if (
      !this.canCreate &&
      ((!this.canEdit && !this.isOwnProfile()) ||
        (!this.canEditOwn && this.isOwnProfile()))
    ) {
      this._logAndMessage.error(
        'No Permissions',
        'User does not have permissions to save contact.'
      );
      return;
    }
    if (this.form.valid) {
      this.saving = true;
      this._cdRef.markForCheck();
      const c = Object.assign({}, this.form.getRawValue());
      const dv = this.getDirtyValues(this.form);
      if (!c.id) {
        const create = this._contactBuilder.buildCreateObject(c);
        const contact = Object.assign({}, create);
        this.createContact(contact);
      } else {
        const update = this._contactBuilder.buildUpdateStatement(
          this.contact,
          dv
        );
        const contact = Object.assign({}, this.contact);
        this.updateContact(contact, update);
      }
    } else {
      this._logAndMessage.translateToErrorMessage({
        bodyKey: 'COMMON.MESSAGES.ERROR.INVALID_FORM',
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
      });
    }
  }

  getDirtyValues(form: any) {
    const dirtyValues = {};
    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) {
          dirtyValues[key] = this.getDirtyValues(currentControl);
        } else {
          dirtyValues[key] = currentControl.value;
        }
      }
    });

    return dirtyValues;
  }

  createContact(contact): void {
    if (!this.canCreate) {
      this._logAndMessage.error(
        'No Permissions',
        'User does not have permissions to create contact.'
      );
      return;
    }
    this._contactApi
      .createContact(contact)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          this.contact = Object.assign(
            {},
            this._contactBuilder.buildContact(data.createContact)
          );
          this._contactBuilder.updateForm(
            this.form,
            this.contact,
            this.canEdit
          );
          this._cdRef.markForCheck();
        },
        (error) => {
          this.saving = false;
          console.log(error);
        },
        () => {
          this.changesEvent.emit({
            isDirty: false,
            index: this.index,
            id: this.id,
          });
          this.saving = false;
          this._logAndMessage.translateToSuccessMessage({
            bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
            headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
          });
          if (!this._deviceService.isMobile()) {
            const oldTab = TabService.getInstance().getActiveTab();
            const tab = TabService.getInstance().buildNewTab(
              CONTACT_DETAIL_CONTAINER,
              true,
              null,
              this.contact.id
            );
            TabService.getInstance().replaceTab(oldTab, tab);
          }
        }
      );
  }

  findContactAndNavigate(event) {
    // hack - using router link here so we can get the id.
    // don't want to reload the page.
    const id = event.item.optional;
    if (id) {
      this._contactApi
        .getContactById(id)
        .pipe(take(1))
        .subscribe(({ data }) => {
          const clone = Object.assign({}, data);
          const newContact = this._contactBuilder.buildContact(
            clone.getContact
          );
          this.contact = Object.assign({}, newContact);
          this._contactBuilder.updateForm(
            this.form,
            this.contact,
            this.canEdit
          );
          this.checkAvailability();
          this.findReportingChain();
          this._breadCrumbService.removeLaterCrumbs(event.item);
          this._cdRef.markForCheck();
        });
    }
  }

  updateContact(contact, update) {
    if (
      (!this.canEdit && !this.isOwnProfile()) ||
      (!this.canEditOwn && this.isOwnProfile())
    ) {
      this._logAndMessage.error(
        'No Permissions',
        'User does not have permissions to update contact.'
      );
      return;
    }
    contact.updater = null;
    contact.updatedAt = null;
    this._contactApi
      .updateContact(contact.id, update)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          this._logAndMessage.translateToSuccessMessage({
            bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
            headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
          });
          this.contact = Object.assign(
            {},
            this._contactBuilder.buildContact(data.updateContact)
          );
          this._contactBuilder.updateForm(
            this.form,
            this.contact,
            this.canEdit
          );
          this._cdRef.markForCheck();
        },
        (error) => {
          console.log(error);
          this.saving = false;
        },
        () => {
          this.saving = false;
          this.findReportingChain();
          this.changesEvent.emit({
            isDirty: false,
            index: this.index,
            id: this.id,
          });
        }
      );
  }

  buildForm() {
    this.form = this._contactBuilder.buildForm(this._fb, this.contact);

    if (
      (!this.canEdit && !this.isOwnProfile()) ||
      (!this.canEditOwn && this.isOwnProfile())
    ) {
      this.form.disable();
    }

    this.valueSub = this.form.valueChanges.subscribe((changes) => {
      if (this.form.dirty) {
        this.changesEvent.emit({
          isDirty: true,
          index: this.index,
          id: this.id,
        });
      }
    });

    this.setupAfterHoursValidation();
  }

  supervisorSearch($event) {
    const query = {
      firstName: $event.query.toLowerCase(),
    };

    const sort = {
      firstName: 'ASC',
    };
    this._contactApi
      .queryForContactDropdown(999999, 1, query, sort)
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = Object.assign({}, data);

        this.filteredContacts = [
          ...clone.queryForContactDropdown.items.map((c) => ({
            name: c.firstName + ' ' + c.lastName,
            id: c.id,
          })),
        ];
      });
  }

  updateAvailability(id) {
    const availability = this.contact.availabilities.filter(
      (a) => a.id === id
    )[0];
    this.selectedAvailability = Object.assign({}, availability);
    this.startTime = new Date(this.selectedAvailability.start);
    this.endTime = new Date(this.selectedAvailability.end);
    this.modifyAvailabilityButtonLabel = this.updateLabel;
    this.modifyAvailabilityIcon = this.updateIcon;
  }

  deleteAvailability(id) {
    this._confirmationService.confirm({
      header: this.deleteHeader,
      icon: 'fa fa-question-circle',
      acceptVisible: true,
      rejectVisible: true,
      acceptLabel: this.okBtnLabel,
      rejectLabel: this.cancelBtnLabel,
      message: this.deleteMsg,
      accept: () => {
        const availability = this.contact.availabilities.filter(
          (a) => a.id === id
        )[0];
        const idx = this.contact.availabilities.findIndex((a) => a.id === id);
        if (availability.dirtyStatus === DirtyStatus.NEW) {
          const someArray = reject(
            this.contact.availabilities,
            (el) => el.id === id
          );
          this.contact.availabilities = [...someArray];
        } else {
          availability.dirtyStatus = DirtyStatus.DELETED;
          this.saveContact();
        }
      },
      reject: () => {},
    });
  }

  clearAvailability() {
    this.startTime = null;
    this.endTime = null;
    this.selectedAvailability = null;
    this.modifyAvailabilityButtonLabel = this.addLabel;
    this.modifyAvailabilityIcon = this.addIcon;
  }

  modifyAvailability($event) {
    this.startTime = $event.startTime;
    this.endTime = $event.endTime;
    if (this.selectedAvailability) {
      const availability = this.contact.availabilities.find(
        (a) => a.id === this.selectedAvailability.id
      );
      availability.start = this.startTime;
      availability.end = this.endTime;
      availability.dirtyStatus = DirtyStatus.UPDATED;
    } else {
      // create;
      const availability: Availability = {
        id: uuid(),
        ContactId: this.contact.id,
        start: this.startTime,
        end: this.endTime,
        version: 0,
        dirtyStatus: DirtyStatus.NEW,
      };

      this.contact.availabilities = [
        ...this.contact.availabilities,
        availability,
      ];
    }

    this.saveContact();
    this.clearAvailability();
  }

  checkAvailability() {
    this.isUnavailable = false;
    const today = new Date();
    if (this.contact.availabilities) {
      this.contact.availabilities.forEach((a) => {
        if (today >= new Date(a.start) && today <= new Date(a.end)) {
          this.isUnavailable = true;
        }
      });
    }
  }

  openFacility($event) {
    const tab = TabService.getInstance().buildNewTab(
      FACILITY_DETAIL_CONTAINER,
      true,
      null,
      $event.id
    );
    if (this._deviceService.isMobile()) {
      TabService.getInstance().setMobileTab(tab);
    } else {
      TabService.getInstance().openTab(tab);
    }
  }

  openContactList($event) {
    if ($event.ContactListId) {
      this._contactListTable.setSelected(undefined);
      const tab = TabService.getInstance().buildNewTab(
        CONTACT_LIST_DETAIL_CONTAINER,
        true,
        null,
        $event.ContactListId
      );
      if (this._deviceService.isMobile()) {
        TabService.getInstance().setMobileTab(tab);
      } else {
        TabService.getInstance().openTab(tab);
      }
    }
  }

  loadFacilities($event) {
    if (!isNullOrUndefined($event)) {
      this.tabIndex = $event.index;
    }

    if (!this.facilities) {
      this.facilities = [];
      this._contactListApi
        .getContactListForContact(this.contact.id)
        .pipe(take(1))
        .subscribe(({ data }) => {
          const clone = Object.assign({}, data);
          const facs = [];
          clone.getContactListsForContact.forEach((cl) => {
            facs.push(cl.Facilities);
          });
          // facs is an array of arrays, so reduce it to flat array, find only unique and then map to facilties
          this.facilities = [
            ...facs
              .reduce((acc, val) => acc.concat(val), [])
              .filter(onlyUnique)
              .map((f) => this._facilityBuilder.buildFacility(f, [])),
          ];

          this.facilitiesLoaded = true;
          this._cdRef.markForCheck();
        });
    }
  }

  checkAndResetAfterHours(changes, key) {
    if (
      changes === '___-___-____' &&
      this.form.controls['afterHoursContactMethod'].value === key
    ) {
      this._logAndMessage.translateToWarnMessage({
        headerKey: 'CONTACT.MESSAGES.HEADERS.CONTACT_METHOD_RESET',
        bodyKey: 'CONTACT.MESSAGES.WARN.CONTACT_METHOD_RESET_MSG',
      });
      this.form.controls['afterHoursContactMethod'].patchValue('');
      this.form.controls['afterHoursContactMethod'].markAsDirty();
    }
  }

  setupAfterHoursValidation() {
    this.personalMobileSub = this.form.controls[
      'personalMobile'
    ].valueChanges.subscribe((changes) => {
      this.checkAndResetAfterHours(changes, 'PERSONAL CELL');
    });

    this.businessMobileSub = this.form.controls[
      'businessMobile'
    ].valueChanges.subscribe((changes) => {
      this.checkAndResetAfterHours(changes, 'CELLULAR');
    });

    this.businessPhoneSub = this.form.controls[
      'businessPhone'
    ].valueChanges.subscribe((changes) => {
      this.checkAndResetAfterHours(changes, 'OFFICE');
    });

    this.homeSub = this.form.controls['home'].valueChanges.subscribe(
      (changes) => {
        this.checkAndResetAfterHours(changes, 'HOME');
      }
    );

    this.pagerSub = this.form.controls['other'].valueChanges.subscribe(
      (changes) => {
        this.checkAndResetAfterHours(changes, 'OTHER');
      }
    );

    this.speedDialSub = this.form.controls['speedDial'].valueChanges.subscribe(
      (changes) => {
        if (
          (changes === null || changes.trim() === '') &&
          this.form.controls['afterHoursContactMethod'].value === 'SPEED_DIAL'
        ) {
          this._logAndMessage.translateToWarnMessage({
            headerKey: 'CONTACT.MESSAGES.HEADERS.CONTACT_METHOD_RESET',
            bodyKey: 'CONTACT.MESSAGES.WARN.CONTACT_METHOD_RESET_MSG',
          });
          this.form.controls['afterHoursContactMethod'].patchValue('');
        }
      }
    );

    this.afterHoursSub = this.form.controls[
      'afterHoursContactMethod'
    ].valueChanges.subscribe((changes) => {
      let reset = false;
      let str = '';
      if (
        changes === 'HOME' &&
        isNullOrEmpty(this.form.controls['home'].value)
      ) {
        reset = true;
        str = 'Home';
      } else if (
        changes === 'CELLULAR' &&
        isNullOrEmpty(this.form.controls['businessMobile'].value)
      ) {
        reset = true;
        str = 'Business Mobile';
      } else if (
        changes === 'OFFICE' &&
        isNullOrEmpty(this.form.controls['businessPhone'].value)
      ) {
        reset = true;
        str = 'Business Phone';
      } else if (
        changes === 'PERSONAL CELL' &&
        isNullOrEmpty(this.form.controls['personalMobile'].value)
      ) {
        reset = true;
        str = 'Personal Mobile';
      } else if (
        changes === 'SPEED DIAL' &&
        isNullOrEmpty(this.form.controls['speedDial'].value)
      ) {
        reset = true;
        str = 'Speed Dial';
      } else if (
        changes === 'OTHER' &&
        isNullOrEmpty(this.form.controls['other'].value)
      ) {
        reset = true;
        str = 'Other';
      }

      if (reset) {
        this._logAndMessage.translateToWarnMessage({
          headerKey: 'CONTACT.MESSAGES.HEADERS.INVALID_CONTACT_METHOD',
          bodyKey: 'CONTACT.MESSAGES.WARN.INVALID_CONTACT_METHOD_MSG',
          bodyParams: { label: str },
        });
        this.form.controls['afterHoursContactMethod'].patchValue(
          this.prevAfterHours
        );
        this._cdRef.markForCheck();
      }
    });
  }

  ngDoCheck() {
    if (this.form) {
      this.prevAfterHours = this.form.get('afterHoursContactMethod').value;
    }
  }

  ngAfterContentChecked(): void {
    const activeId = this.id;
    if (
      !isNullOrUndefined(activeId) &&
      !isNullOrUndefined(this.contact) &&
      this.contact.id !== activeId &&
      !this.doCheckComplete
    ) {
      this.doCheckComplete = true;
      this.facilities = null;
      this.tabIndex = 0;
      this._contactTable.setSelected(undefined);
      this.initialize();
    }
  }

  enableForm() {
    if (this.form) {
      this.form.enable();
      this.form.controls['managerLevel'].disable();
      this.form.controls['directorLevel'].disable();
      this.form.controls['activeStatus'].disable();
      this.form.controls['email'].disable();
      this._cdRef.markForCheck();
    }
  }

  findReportingChain() {
    // this block of code checks to see if the contact loaded has a reporting chain.
    // if we are cache enabled search the cache, if not use queryForWhoReportsTo me to find
    // the chain.  If the loaded contact has employees that report to them we make the
    // when unavailable field visible.
    // see who reports to contact loaded.
    this._contactApi
      .queryForWhoReportsToMe(this.contact.adAccountName)
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = Object.assign({}, data);
        this.allowedToModifyIds = clone.queryWhoReportsToMe.map((c) => c.id);
        this.showWhenUnavailable =
          this.allowedToModifyIds && this.allowedToModifyIds.length > 0;
        this._cdRef.markForCheck();
      });

    if (this.canEdit || (this.canEditOwn && this.isOwnProfile())) {
      this.enableForm();
    } else {
      // see if contact loaded reports to user logged in.
      this._contactApi
        .queryForWhoReportsToMe(this._auth.getEmail())
        .pipe(take(1))
        .subscribe(({ data }) => {
          const clone = Object.assign({}, data);
          this.allowedToModifyIds = clone.queryWhoReportsToMe.map((c) => c.id);
          if (
            this.allowedToModifyIds.some((i) => i === this.contact.id) &&
            this.canEditDirectReports
          ) {
            this.canEdit = true;
            this.enableForm();
          } else {
            if (
              (!this.canEdit && !this.isOwnProfile()) ||
              (!this.canEditOwn && this.isOwnProfile())
            ) {
              this.form.disable();
            }
          }
          this._cdRef.markForCheck();
        });
    }

    if (this._authApi.doesUserHaveAllClaimsFromList(['ViewAllPII'])) {
      this.canViewPII = true;
    }

    if (
      this.isOwnProfile() &&
      this._authApi.doesUserHaveAllClaimsFromList(['ViewOwnPII'])
    ) {
      this.canViewPII = true;
    }

    // see if contact loaded reports to user logged in.
    this._contactApi
      .queryForWhoReportsToMe(this._auth.getEmail())
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = Object.assign({}, data);
        this.allowedToModifyIds = clone.queryWhoReportsToMe.map((c) => c.id);

        if (
          this._authApi.doesUserHaveAllClaimsFromList(['ViewDirectReportsPII'])
        ) {
          if (this.allowedToModifyIds.some((i) => i === this.contact.id)) {
            this.canViewPII = true;
          }
        }

        if (
          this.allowedToModifyIds.some((i) => i === this.contact.id) &&
          this.canEditDirectReports
        ) {
          this.canEdit = true;
          this.enableForm();
        } else {
          if (
            (!this.canEdit && !this.isOwnProfile()) ||
            (!this.canEditOwn && this.isOwnProfile())
          ) {
            this.form.disable();
          }
        }
        this._cdRef.markForCheck();
      });
  }

  doesContactLoadedReportToCurrentUser(contacts, inId) {
    let retVal = false;
    const firstLevel = contacts
      .filter((c) => c.ReportsTo && c.ReportsTo.id === inId)
      .map((c) => c.id);
    this.showWhenUnavailable = firstLevel && firstLevel.length > 0;
    if (!this.canEdit) {
      if (firstLevel.some((id) => id === inId)) {
        retVal = true;
      }
      firstLevel.forEach((firstId) => {
        const secondLevel = contacts
          .filter((c) => c.ReportsTo && c.ReportsTo.id === firstId)
          .map((c) => c.id);
        if (secondLevel.some((id) => id === inId)) {
          retVal = true;
        } else {
          secondLevel.forEach((secondId) => {
            const thirdLevel = contacts
              .filter((c) => c.ReportsTo && c.ReportsTo.id === secondId)
              .map((c) => c.id);
            if (thirdLevel.some((id) => id === inId)) {
              retVal = true;
            }
          });
        }
      });
    }

    return retVal;
  }

  findContact(id) {
    this._contactApi
      .getContactById(id)
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = Object.assign({}, data);
        if (this._deviceService.isMobile()) {
          this.contact = Object.assign(
            {},
            this._contactBuilder.buildContact(clone.getContact)
          );
          this._contactBuilder.updateForm(
            this.form,
            this.contact,
            this.canEdit
          );
          this.findReportingChain();
          const contactBreadCrumb = new BreadCrumb(
            this.contact.firstName + ' ' + this.contact.lastName,
            null,
            true,
            this.contact.id
          );
          this._breadCrumbService.addBreadCrumb(contactBreadCrumb);
          this._cdRef.markForCheck();
          this.checkAvailability();
        } else {
          const c = Object.assign(
            {},
            this._contactBuilder.buildContact(clone.getContact)
          );
          const tab = TabService.getInstance().buildNewTab(
            CONTACT_DETAIL_CONTAINER,
            true,
            null,
            c.id
          );
          TabService.getInstance().openTab(tab);
        }
      });
  }

  updateActionsList() {
    const selectAction$ = this._translateService.get(
      'COMMON.LABEL.SELECT_ACTION'
    );
    const viewAudit$ = this._translateService.get(
      'COMMON.LABEL.VIEW_AUDIT_INFO'
    );

    let select,
      audit = null;
    forkJoin([selectAction$, viewAudit$])
      .pipe(take(1))
      .subscribe((messages) => {
        select = messages[0];
        audit = messages[1];

        this.actions = [
          { label: select, value: null },
          { label: audit, value: VIEW_AUDIT },
        ];

        // default actions here
        this.actions = [...this.actions];
      });
  }

  actionEvent($event, object?: any) {
    if ($event.value) {
      this.selectedAction = null;
      if ($event.value) {
        if ($event.value === VIEW_AUDIT) {
          this.auditLoading = true;
          this._contactApi
            .findContactHistory(this.contact.id)
            .pipe(take(1))
            .subscribe(
              ({ data }) => {
                const clone = Object.assign({}, data);
                this.history = [...buildAuditHistory(clone.getContactHistory)];
                this._cdRef.markForCheck();
              },
              (error) => {
                this.auditLoading = false;
              },
              () => {
                this.auditLoading = false;
                this.displayAuditDialog = true;
              }
            );
        }
      }

      if (!isNullOrUndefined(object)) {
        object.clear(null);
      }
    }
    this._cdRef.markForCheck();
  }

  availableForContact(): boolean {
    return (
      !isNullOrUndefined(this.contact) && !isNullOrUndefined(this.contact.id)
    );
  }

  isOwnProfile(): boolean {
    return (
      !isNullOrUndefined(this.contact) &&
      !isNullOrUndefined(this.contact.adAccountName) &&
      !isNullOrUndefined(this._auth.getEmail()) &&
      this.contact.adAccountName.toLowerCase() ===
        this._auth.getEmail().toLowerCase()
    );
  }

  toggleFacilities($event) {
    if (
      !isNullOrUndefined($event) &&
      !isNullOrUndefined($event.collapsed) &&
      $event.collapsed === false
    ) {
      this.loadFacilities(null);
    }
  }

  clearAsteriskFromTab() {
    this.changesEvent.emit({
      isDirty: false,
      index: this.index,
      id: this.id,
    });
  }
}
