import { Injectable } from '@angular/core';
import { ExternalContactList } from '../model/contacts/external-contact-list';
import { BaseTableService } from './base-table.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalContactListTableService extends BaseTableService<ExternalContactList> {
  constructor() {
    super();
   }
}
