import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent } from 'primeng/api';
import { take } from 'rxjs/operators';
import { EXTERNAL_LIST_DETAIL_CONTAINER } from 'src/app/constants/common.constants';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { DeviceSize } from 'src/app/core/enums/deviceSize.enum';
import { TabService } from 'src/app/core/services/tab.service';
import { ExternalContactList } from 'src/app/model/contacts/external-contact-list';
import { AuthService } from 'src/app/services/auth.service';
import { ContactListApiService } from 'src/app/services/contact-list-api.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { ContactListBuilderService } from 'src/app/services/contact-list-builder.service';
import { DeviceService } from 'src/app/services/device.service';
import { ExternalContactListTableService } from 'src/app/services/external-contact-list-table.service';
import { LoadingService } from 'src/app/services/loading.service';
import { getExternalContactListColumns } from '../../definitions/external-contact-list-table-definition';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { BreadCrumb } from 'src/app/model/common/bread-crumb';
import { BusinessUnitFilterComponent } from 'src/app/core/components/business-unit-filter/business-unit-filter.component';

@Component({
  selector: 'app-external-contact-list-search-container',
  templateUrl: './external-contact-list-search-container.component.html',
  styleUrls: ['./external-contact-list-search-container.component.scss'],
})
export class ExternalContactListSearchContainerComponent
  extends SearchContainerComponent<ExternalContactList>
  implements OnInit
{
  @ViewChild('buFilter') businessUnitFilter: BusinessUnitFilterComponent;

  allContactLists: any[];
  loading: boolean;
  contactsLoading: boolean;
  availableCompanies: any[];
  totalRecords: number;
  contactsLoadingEvent = new EventEmitter<any>();
  canEdit = false;
  scrollHeight = 'calc(100vh - 256px)';
  mobileFilterCollapsed = true;

  constructor(
    private _translateService: TranslateService,
    private _breadCrumbBuilderService: BreadCrumbBuilderService,
    protected _deviceService: DeviceService,
    private _contactListApi: ContactListApiService,
    private _contactListBuilder: ContactListBuilderService,
    private _contactListTableService: ExternalContactListTableService,
    private _authApi: AuthApiService,
    protected _cdRef: ChangeDetectorRef,
    protected _auth: AuthService,
    protected _loader: LoadingService
  ) {
    super(_deviceService, _loader, _auth, _cdRef);
  }

  ngOnInit() {
    this.setTableService(this._contactListTableService);

    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.subscribe((event) => {
        this.initialize();
      });
    }
  }

  initialize() {
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([
      'EditExternalLists',
    ]);
    this._translateService
      .get('CONTACT.SCREEN.EXTERNAL_CONTACT_LIST')
      .subscribe((label) => {
        if (this._deviceService.isMobile()) {
          this._breadCrumbBuilderService.resetAndAddBreadCrumb(
            new BreadCrumb(label, null, false)
          );
        } else {
          TabService.getInstance().updateActiveTabLabel(label);
        }
        this.screenName = label;
      });

    if (this.ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])) {
      this.scrollHeight = 'calc(100vh - 180px)';
    }

    this.columns = getExternalContactListColumns();
    this.applyPreviousData();
    this.applyPreviousFilters();
  }

  /**
   * Method called when the user hits the clear button.
   */
  clearScreen() {
    this.lazyLoad({ lazy: {} });
    this.grid.resetTable();
    this._contactListTableService.clearResults();
    this.elements = [];
  }

  /**
   * Method called when a row double click event is fired.  This navigates to the details page.
   * @param $event - Event from the double click event.
   */
  changeViewEvent($event) {
    const tab = TabService.getInstance().buildNewTab(
      EXTERNAL_LIST_DETAIL_CONTAINER,
      true,
      null,
      $event.data.id
    );
    if (this._deviceService.isMobile()) {
      TabService.getInstance().setMobileTab(tab);
    } else {
      TabService.getInstance().openTab(tab);
    }
  }

  newContactList() {
    const ext = this._contactListBuilder.buildNullExternalContactList();
    this._contactListTableService.setSelected(ext);
    const tab = TabService.getInstance().buildNewTab(
      EXTERNAL_LIST_DETAIL_CONTAINER,
      true
    );
    TabService.getInstance().openTab(tab);
  }

  queryNetwork(req, $event, query) {
    req.page += 1;
    const sort = {};
    if ($event.lazy && $event.lazy.sortField) {
      sort[$event.lazy.sortField] =
        $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'DESC' : 'ASC';
      this._contactListTableService.setLastSortField($event.lazy.sortField);
      this._contactListTableService.setLastSortDirection($event.lazy.sortOrder);
    } else {
      sort['name'] = 'ASC';
      this._contactListTableService.setLastSortField('name');
      this._contactListTableService.setLastSortDirection(1);
    }

    this._contactListApi
      .queryForExternalContactLists(req.pageSize, req.page, query, sort)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          const clone = Object.assign({}, data);
          // filter based on query
          this.allContactLists = [
            ...clone.queryForExternalContactLists.items.map((i) =>
              this._contactListBuilder.buildContactList(i)
            ),
          ];
          this.elements = [...this.allContactLists];
          this.finalizeQuery(clone.queryForExternalContactLists.totalRecords);
        },
        (error) => {
          console.log(error);
          // TODO Message to user
        }
      );
  }

  handleLazyLoad(req, $event, filters) {
    let selectedBusinessUnits = [];
    if (this.businessUnitFilter?.selectedBusinessUnits) {
      // Handle further refreshes & changes to business unit filter component
      selectedBusinessUnits = this.businessUnitFilter.selectedBusinessUnits;
    } else {
      // Handle initial page load (before the business unit filter subcomponent exists)
      selectedBusinessUnits = this._authApi
        .getUserBusinessUnits()
        .map((bu) => bu.id);
    }
    filters['businessUnits'] = selectedBusinessUnits;

    const query =
      this._contactListBuilder.buildExternalContactListQuery(filters);
    this._contactListTableService.setLastQuery(query);
    this.queryNetwork(req, $event, query);
  }

  mobileFilter($event) {
    const newLazy: LazyLoadEvent = {
      filters: {
        ...this.grid.table.filters,
        name: $event.name
          ? {
              value: $event.name,
              matchMode: 'contains',
            }
          : null,
      },
    };
    this.mobileFilterCollapsed = true;
    this._contactListTableService.setLastLazyLoad(newLazy);
    this.lazyLoad({ lazy: this._contactListTableService.getLastLazyLoad() });
  }

  refresh(): void {
    this.lazyLoad({ lazy: this._contactListTableService.getLastLazyLoad() });
  }

  handleBusinessUnitChange($event) {
    this.lazyLoad({ lazy: this._contactListTableService.getLastLazyLoad() });
  }
}
