import { Column } from 'src/app/model/common/column';

export function getExternalContactListMemberDefinition(): Column[] {
    return [
        {
            header: 'CONTACT.LABEL.NAME',
            mobileHeader: 'CONTACT.LABEL.NAME',
            field: 'name',
            formatPattern: 'text',
            width: '36%',
        },
        {
            header: 'CONTACT.LABEL.DESCRIPTION',
            mobileHeader: 'CONTACT.LABEL.DESCRIPTION',
            field: 'description',
            formatPattern: 'text',
            width: '32%',
        },
        {
            header: 'CONTACT.LABEL.PHONE',
            mobileHeader: 'CONTACT.LABEL.PHONE',
            field: 'phone',
            formatPattern: 'text',
            width: '32%',
        },
    ];
}
