import { gql } from 'graphql-tag'

export const addContactListSchedule = gql`
mutation addContactListSchedule($input: ContactListScheduleInput){
    addContactListSchedule(input: $input){
        id
        isPrimary
        startTime
        endTime
        timezone
    }
}`
