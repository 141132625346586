<div
  *ngIf="member?.contact"
  [class.mgr-select-class]="selected === true"
  id="main-div"
>
  <div [className]="cardClassName()">
    <div
      class="no-padding-bottom contact-header"
      style="border-bottom: 1px solid black"
    >
      <div class="contact-name-label small-padding">
        <span *ngIf="index">{{ index }}.</span>
        {{ member.contact.firstName }} {{ member.contact.lastName }}
        <span *ngIf="member.contact.isUnavailable === 'TRUE'">{{
          "CONTACT.LABEL.UNAVAILABLE" | translate
        }}</span>
      </div>
      <div class="contact-overflow small-padding">
        {{ member.contact.jobRoleItem?.label }}
      </div>
      <div class="contact-overflow small-padding">
        {{ member.contact.companyAbbreviation }}
      </div>
      <div class="small-padding">
        <i class="fa fa-fw fa-id-card fa-2x" (click)="openProfile()"></i>
      </div>
    </div>

    <div
      *ngIf="
        member.contact.isUnavailable === 'TRUE' &&
        member.contact.whenUnavailableLevel
      "
    >
      <span
        >When Unavailable Contact:
        {{ member.contact.whenUnavailableLevel }}</span
      >
      <div class="small-padding">
        <i
          class="fa fa-fw fa-id-card fa-2x"
          (click)="openProfile(member.contact.whenUnavailableId)"
        ></i>
      </div>
    </div>

    <div class="contact-body">
      <div class="no-padding-top-bottom body-row">
        <div class="no-padding" *ngIf="member.contact.businessPhone">
          <span class="label no-padding"
            >{{ "CONTACT.LABEL.OFFICE" | translate }}:</span
          >
          {{ member.contact.businessPhone }}
          <span
            *ngIf="
              this.member.contact.afterHoursContactMethod != null &&
              (this.member.contact.afterHoursContactMethod.value
                ? 'OFFICE' === this.member.contact.afterHoursContactMethod.value
                : false)
            "
          >
            <i class="fa fa-fw fa-check-circle" style="color: green"></i>
            {{ "CONTACT.LABEL.PREFERRED" | translate }}
          </span>
        </div>
        <div class="no-padding" *ngIf="member.contact.home">
          <span class="label no-padding"
            >{{ "CONTACT.LABEL.HOME" | translate }}:</span
          >
          {{ member.contact.home }}
          <span
            *ngIf="
              this.member.contact.afterHoursContactMethod != null &&
              (this.member.contact.afterHoursContactMethod.value
                ? 'HOME' === this.member.contact.afterHoursContactMethod.value
                : false)
            "
          >
            <i class="fa fa-fw fa-check-circle" style="color: green"></i>
            {{ "CONTACT.LABEL.PREFERRED" | translate }}
          </span>
        </div>
        <div class="no-padding" *ngIf="member.contact.other">
          <span class="label no-padding"
            >{{ "CONTACT.LABEL.OTHER" | translate }}:</span
          >
          {{ member.contact.other }}
          <span *ngIf="member.contact.note">- {{ member.contact.note }}</span>
          <span
            *ngIf="
              this.member.contact.afterHoursContactMethod != null &&
              (this.member.contact.afterHoursContactMethod.value
                ? 'PAGER' === this.member.contact.afterHoursContactMethod.value
                : false)
            "
          >
            <i class="fa fa-fw fa-check-circle" style="color: green"></i>
            {{ "CONTACT.LABEL.PREFERRED" | translate }}
          </span>
        </div>
        <div class="no-padding" *ngIf="member.contact.businessMobile">
          <span class="label no-padding"
            >{{ "CONTACT.LABEL.CELLULAR" | translate }}:</span
          >
          {{ member.contact.businessMobile }}
          <span
            *ngIf="
              this.member.contact.afterHoursContactMethod != null &&
              (this.member.contact.afterHoursContactMethod.value
                ? 'CELLULAR' ===
                  this.member.contact.afterHoursContactMethod.value
                : false)
            "
          >
            <i class="fa fa-fw fa-check-circle" style="color: green"></i>
            {{ "CONTACT.LABEL.PREFERRED" | translate }}
          </span>
        </div>
        <div class="no-padding" *ngIf="member.contact.speedDial">
          <span class="label no-padding"
            >{{ "CONTACT.LABEL.SPEED_DIAL" | translate }}:</span
          >
          {{ member.contact.speedDial }}
        </div>
        <div class="no-padding" *ngIf="member.contact.personalMobile">
          <span class="label no-padding"
            >{{ "CONTACT.LABEL.PERSONAL_MOBILE" | translate }}:</span
          >
          {{ member.contact.personalMobile }}
          <span
            *ngIf="
              this.member.contact.afterHoursContactMethod != null &&
              (this.member.contact.afterHoursContactMethod.value
                ? 'PERSONAL CELL' ===
                  this.member.contact.afterHoursContactMethod.value
                : false)
            "
          >
            <i class="fa fa-fw fa-check-circle" style="color: green"></i>
            {{ "CONTACT.LABEL.PREFERRED" | translate }}
          </span>
        </div>
        <div class="no-padding" *ngIf="member.contact.adAccountName">
          <span class="label no-padding"
            >{{ "CONTACT.LABEL.EMAIL" | translate }}:</span
          >
          {{ member.contact.adAccountName | lowercase }}
        </div>
      </div>

      <div class="body-row">
        <div class="no-padding" *ngIf="member.contact.supervisor">
          <span class="label no-padding"
            >{{ "CONTACT.LABEL.SUPERVISOR" | translate }}:</span
          >
          {{ member.contact.supervisor }}
          <i
            class="fa fa-fw fa-id-card fa-2x"
            (click)="openProfile(member.contact.reportsToId)"
          ></i>
        </div>
        <div class="no-padding" *ngIf="member.contact.company">
          <span class="label no-padding"
            >{{ "CONTACT.LABEL.COMPANY" | translate }}:</span
          >
          {{ member.contact.company }}
        </div>
      </div>
    </div>
  </div>
</div>
