import { Column } from '../../model/common/column';
import { SelectItem } from 'primeng/api';

export function getContactTableColumns(
  availableCompanies: SelectItem[],
  availableStatus: SelectItem[],
  isMobile: boolean
): Column[] {
  const retVal = [];

  if (!isMobile) {
    retVal.push(
      {
        field: 'firstName',
        header: 'CONTACT.LABEL.FIRST_NAME',
        formatPattern: 'text',
        width: '11%',
        sortable: true,
        filterable: true,
        matchMode: 'startsWith',
      },
      {
        field: 'lastName',
        header: 'CONTACT.LABEL.LAST_NAME',
        formatPattern: 'text',
        width: '11%',
        sortable: true,
        filterable: true,
        matchMode: 'startsWith',
      }
    );
  } else if (isMobile) {
    retVal.push({
      field: 'fullName',
      header: 'CONTACT.LABEL.FULL_NAME',
      mobileHeader: 'CONTACT.LABEL.FULL_NAME',
      formatPattern: 'hyperlink',
      width: '20%',
      sortable: true,
      filterable: true,
      matchMode: 'startsWith',
      viewType: 'contact',
    });
  }

  retVal.push(
    {
      field: 'company',
      header: 'CONTACT.LABEL.COMPANY',
      formatPattern: 'text',
      type: 'dropdown',
      width: '11%',
      sortable: true,
      filterable: true,
      options: availableCompanies,
      matchMode: 'startsWith',
    },
    {
      field: 'businessPhone',
      header: 'CONTACT.LABEL.BUSINESS_PHONE',
      mobileHeader: 'CONTACT.LABEL.BUSINESS_PHONE',
      formatPattern: 'text',
      width: '11%',
      sortable: true,
      filterable: true,
      matchMode: 'startsWith',
    },
    {
      field: 'businessMobile',
      header: 'CONTACT.LABEL.BUSINESS_MOBILE',
      mobileHeader: 'CONTACT.LABEL.BUSINESS_MOBILE',
      formatPattern: 'text',
      width: '11%',
      sortable: true,
      filterable: true,
      matchMode: 'startsWith',
    },
    {
      field: 'adAccountName',
      header: 'CONTACT.LABEL.EMAIL',
      mobileHeader: 'CONTACT.LABEL.EMAIL',
      formatPattern: 'text_lower',
      width: '11%',
      sortable: true,
      filterable: true,
      matchMode: 'startsWith',
    },
    {
      field: 'supervisor',
      header: 'CONTACT.LABEL.SUPERVISOR',
      mobileHeader: 'CONTACT.LABEL.SUPERVISOR',
      formatPattern: 'text',
      width: '11%',
      sortable: true,
      filterable: true,
      matchMode: 'startsWith',
    },
    {
      field: 'jobRole',
      header: 'CONTACT.LABEL.JOB_ROLE',
      formatPattern: 'text',
      width: '11%',
      sortable: true,
      filterable: true,
      matchMode: 'contains',
    }
  );

  return retVal;
}
