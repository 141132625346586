export const timezones = [
  {
    label: 'Eastern',
    value: 'America/New_York',
  },
  {
    label: 'Central',
    value: 'America/Chicago',
  },
  {
    label: 'Mountain',
    value: 'America/Denver',
  },
  {
    label: 'Pacific',
    value: 'America/Los_Angeles',
  },
];
