<p-dialog
  header="Schedule Contacts"
  [modal]="true"
  [(visible)]="isVisible"
  (onHide)="onDialogHide($event)"
  [dismissableMask]="true"
  [contentStyle]="{ overflow: 'visible' }"
  [focusOnShow]="false"
>
  <div class="container">
    <form #scheduleForm [formGroup]="scheduleFormGroup" *ngIf="!isLoading">
      <div class="start-time">
        <label for="startTime">Start</label>
        <p-calendar
          formControlName="startTime"
          [showTime]="true"
          hourFormat="24"
          [minDate]="minDateTime"
        ></p-calendar>
      </div>
      <div
        class="field-message"
        *ngIf="scheduleFormGroup.errors?.['start'] && scheduleFormGroup.dirty"
      >
        Start time has already passed.
      </div>
      <div class="end-time">
        <label for="endTime">End</label>
        <p-calendar
          formControlName="endTime"
          [showTime]="true"
          hourFormat="24"
          [minDate]="minDateTime"
        ></p-calendar>
      </div>
      <div class="field-message" *ngIf="scheduleFormGroup.errors?.['end']">
        End time occurs before Start.
      </div>
      <div class="time-zone dropdown ui-grid ui-grid-responsive ui-fluid">
        <label for="timeZone">Time Zone </label>
        <div>
          <p-dropdown
            formControlName="scheduleTimeZone"
            optionLabel="label"
            [options]="timeZones"
            (onChange)="onTimeZoneChange($event)"
            appendTo="body"
            [style]="{ width: '100%' }"
          ></p-dropdown>
        </div>
      </div>
      <div class="primary dropdown ui-grid ui-grid-responsive ui-fluid">
        <label for="primary">Primary </label>
        <div>
          <p-dropdown
            [options]="contacts"
            formControlName="primary"
            (onChange)="onPrimaryContactChange($event)"
            placeholder="Select Primary"
            optionLabel="fullName"
            [showClear]="true"
            [style]="{ width: '100%' }"
          ></p-dropdown>
        </div>
      </div>
      <div class="secondary dropdown ui-grid ui-grid-responsive ui-fluid">
        <label for="secondary">Secondary </label>
        <div>
          <p-dropdown
            [options]="contacts"
            formControlName="secondary"
            (onChange)="onSecondaryContactChange($event)"
            placeholder="Select Secondary"
            optionLabel="fullName"
            [showClear]="true"
            [style]="{ width: '100%' }"
          ></p-dropdown>
        </div>
      </div>
      <div *ngIf="saving">
        <app-progress-spinner [enabled]="saving"></app-progress-spinner>
      </div>
      <div
        class="field-message"
        *ngIf="scheduleFormGroup.errors?.['required'] && scheduleFormGroup.dirty"
      >
        A Contact is required.
      </div>
      <div
        class="field-message"
        *ngIf="scheduleFormGroup.errors?.['match'] && scheduleFormGroup.dirty"
      >
        Contacts cannot match.
      </div>
      <div class="button">
        <button
          pButton
          pRipple
          label="Delete"
          class="p-button-raised p-button-success"
          [disabled]="disableDelete"
          (click)="deleteContactListSchedule($event)"
        ></button>
        <button
          pButton
          pRipple
          label="Save"
          class="p-button-raised p-button-success right-align"
          [disabled]="!scheduleFormGroup.valid"
          (click)="onSubmit($event)"
        ></button>
      </div>
    </form>
  </div>
</p-dialog>
