import gql from 'graphql-tag';

export const getContactListForContact = gql`
query getContactListsForContact($id: String!) {
  getContactListsForContact(id: $id) {
    id
    name
    Facilities {
      id
      ContactListId
      facilityName
      facilityUid
      scadaId
      Aor {
        name
      }
      FacilityType {
        name
      }
      Locations {
        __typename
        id
        state
        county
        township
      }
    }
  }
}
`;
