import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Facility } from 'src/app/model/locations/facility';
import { DeviceService } from 'src/app/services/device.service';
import { isNullOrEmptyArray } from 'src/app/core/functions/common-functions';

@Component({
  selector: 'app-contact-associate-facilities',
  templateUrl: './contact-associate-facilities.component.html',
  styleUrls: ['./contact-associate-facilities.component.scss'],
})
export class ContactAssociateFacilitiesComponent implements OnInit {
  @Input() facilities: Facility[];
  @Output() openFacilityEvent = new EventEmitter<any>();
  @Output() openContactListEvent = new EventEmitter<any>();

  constructor(private _deviceService: DeviceService) {}

  ngOnInit() {}

  isMobile(): boolean {
    return this._deviceService.isMobile();
  }

  isFacilitiesEmpty(): boolean {
    return isNullOrEmptyArray(this.facilities);
  }
}
