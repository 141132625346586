<div class="container">
  <div class="loading-indicator" *ngIf="working">
    <app-progress-spinner></app-progress-spinner>
  </div>
  <header class="button-container">
    <p-fileUpload
      [customUpload]="true"
      (uploadHandler)="handleFileUpload($event)"
      mode="basic"
      [auto]="true"
      #upload
      class="p-mr-2 mr-1"
      accept=".csv"
      chooseLabel="Upload CSV"
      [disabled]="!!lastUploadedFile"
    ></p-fileUpload>
    <button
      pButton
      type="button"
      class="export-button p-mr-2 mr-1"
      (click)="downloadSchedule()"
      icon="pi pi-download"
      label="{{ 'COMMON.LABEL.BUTTONS.EXPORT' | translate }}"
    ></button>
    <button
      pButton
      class="p-mr-2 p-button-success mr-1"
      icon="pi pi-save"
      label="Save"
      (click)="saveBoundChanges()"
      [disabled]="!saveEnabled"
    ></button>
    <button
      pButton
      class="p-button-danger cancel-button"
      icon="pi pi-times"
      label="Cancel"
      (click)="cancelFile()"
      [disabled]="!lastUploadedFile"
    ></button>
  </header>

  <section>
    <p>Last Uploaded File: {{ lastUploadedFile?.name }}</p>
    <p>Last Upload Time: {{ lastUploadTime | date : "MM/dd/yyyy HH:mm" }}</p>
    <p id="included-header">Included Rows:</p>
    <div class="included-rows">
      <!-- Included rows -->
      <div class="imported-row" *ngFor="let row of importedRows">
        <div [ngClass]="!row.failedParse ? '' : 'warn'">
          User adAccountName: {{ row.adAccountName }}, Primary or Secondary:
          {{ row.primarySecondary }}, Start Time:
          {{ row.startTime | date : "MM/dd/yyyy HH:mm" }}, End Time:
          {{
            row.endTime
              | date
                : "MM/dd/yyyy
                    HH:mm"
          }}, Time Zone: {{ row.timezone }}
          <div class="field-message" *ngIf="row.failedParse">
            {{ row.failMessage }}
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
